<template>
  <div class="sub-category" id="sub-category">
    <div class="temp"></div>
    <div class="content">
      <Loading v-model:active="isLoading"
                 :can-cancel="true"
                 :is-full-page="false"
                 color="#B2CC9B"
                 loader="bars"
                 background-color="#000"
                 :lock-scroll="false"
                 />
      <div class="title">
        <h2 class="color-strong">Subcategory Management</h2>
        <a class="background" data-bs-toggle="modal" data-bs-target="#addSubcategoryModal">Add</a>
      </div>
      <table>
        <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Name</th>
            <th scope="col">Category</th>
            <th scope="col">Items</th>
            <th scope="col">Status</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr class="record" v-for="subcategory in subcategories" :key="subcategory.id" :data-id="subcategory.id" :data-name="subcategory.name">
            <td data-label="ID">{{ subcategory.id }}</td>
            <td data-label="Name">{{ subcategory.name }}</td>
            <td data-label="Category">{{ subcategory.category.name }}</td>
            <td data-label="#Items">{{ subcategory.number_of_items }}</td>
            <td data-label="Status">
              <div class="status">
                <label class="switch">
                  <input type="checkbox" :checked="subcategory.status == 'ACTIVE'"/>
                  <span class="slider round"></span>
                </label>
              </div>
            </td>
            <td data-label="Actions">
              <div class="actions">
                <v-icon icon="mdi-eye-outline" class="view" />
                <v-icon icon="mdi-notebook-edit-outline" class="edit"
                  data-bs-toggle="modal" data-bs-target="#editSubcategoryModal" />
                <v-icon icon="mdi-delete-empty-outline" class="delete"
                  data-bs-toggle="modal" data-bs-target="#deletModal" />
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="6">
              <v-icon icon="mdi-chevron-left" class="icon previous" />
              <span>{{ currPage }} - {{ totalPages }}</span>
              <v-icon icon="mdi-chevron-right" class="icon next" />
            </td>
          </tr>
        </tfoot>
      </table>

      <add-subcategory-modal />
      <edit-subcategory-modal :id="cardId" />
      <app-delete-modal modalTitle="Delete Subcategory"/>
    </div>
  </div>
</template>

<script>
// Loading
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

import store from '@/store'

import AppDeleteModal from '@/components/global/AppDeleteModal.vue'
import AddSubcategoryModal from '@/views/Subcategory/AddSubcategoryModal.vue'
import EditSubcategoryModal from '@/views/Subcategory/EditSubcategoryModal.vue'

export default {
  name: "SubcategoryView",
  data() {
    return {
      subcategories: [],
      currPage: 1,
      totalPages: 0,
      cardId: -1,
      isLoading: false,
    }
  },
  methods: {
    getSubcategories() {
      this.isLoading = true;

      fetch(this.serverURL + 'api/catalog/sub-categories?page=' + this.currPage, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          this.isLoading = false;
          this.subcategories = data.data;
          
          // Meta
          if(data.meta.total > 0){
            this.currPage = data.meta.currentPage;
            this.totalPages = Math.ceil(data.meta.total / data.meta.perPage);
          }else{
            this.currPage = 0;
            this.totalPages = 0;
          }
        })
        .catch(error => {
          console.log(error);
        })
    },
    deleteSubcategory() {
      fetch(this.serverURL + 'api/catalog/categories/' + this.cardId,{
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
        .then(response => response.json())
        .then(function(){
          location.reload();
        })
        .catch()
    },
    activeSubcategory(id) {
      fetch(this.serverURL + 'api/catalog/categories/' + id + '/activate',{
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
        .then(response => response.json())
        .then()
        .catch()
    },
    deactiveSubcategory(id) {
      fetch(this.serverURL + 'api/catalog/categories/' + id + '/deactivate',{
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
        .then(response => response.json())
        .then()
        .catch()
    }
  },
  beforeMount() {
    this.getSubcategories();
  },
  mounted(){
    let that = this;

    // On Checkbox change
    window.$('body').on('change', '.sub-category .content table .status .switch input[type="checkbox"]', function() {
      if (this.checked){
        that.activeSubcategory(window.$(this).parents('.record').data('id'));
      }else{
        that.deactiveSubcategory(window.$(this).parents('.record').data('id'));
      }
    });

    // On View Clicked
    window.$('body').on('click', '.sub-category .content table .actions .view', function() {
      localStorage.setItem('subcategory_id', window.$(this).parents('.record').data('id'));

      let fullPath = '/SubcategoryProducts/' + window.$(this).parents('.record').data('name').replaceAll(' ', '-');
      that.$router.push({ path: fullPath });
    });

    // On Delete Clicked
    window.$('body').on('click', '.sub-category .content table .actions .delete,.sub-category .content table .actions .edit', function() {
      that.cardId = window.$(this).parents('.record').data('id');
    });

    // On Yes Clicked
    window.$('.sub-category .content .yes-btn').on('click', function() {
      that.deleteSubcategory();
    });

    // On Previous Clicked
    window.$('.sub-category .content table .previous').on('click', function() {
      if(that.currPage > 1){
        that.currPage -= 1;
        that.getSubcategories();
      }
    });

    // On Next Clicked
    window.$('.sub-category .content table .next').on('click', function() {      
      if(that.currPage < that.totalPages){
        that.currPage += 1;
        that.getSubcategories();
      }
    });
  },
  components: {
    AppDeleteModal,
    AddSubcategoryModal,
    EditSubcategoryModal,
    Loading
  },
};
</script>

<style>
.sub-category{
  display: flex;
}

/* Temp  */
.sub-category .temp{
  min-width: 250px;
  transition: all .5s;
}
.close-rail .sub-category .temp{
  min-width: 70px;
  transition: all .5s;
}

/* Content */
.sub-category .content{
  position: relative;
  width: -webkit-fill-available;
  background-color: #F1F1F1;
  min-height: 89vh;
  padding: 2rem;
  text-align: left;
}
@media(max-width: 576px){
  .sub-category .content{
    padding: 1rem .5rem;
  }
}
.sub-category .content .title{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}
.sub-category .content .title h2{
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 0;
}
.sub-category .content .title a{
  text-decoration: none;
  color: #FFF;
  cursor: pointer;
  padding: .7rem 2rem;
  border-radius: 10px;
  font-size: 16px;
  font-weight: bold;
  box-shadow: 0 1px 5px #CCC;
}

/* Table */
.sub-category .content table img{
  width: 5rem;
}
.sub-category .content table .actions{
  display: flex;
  align-items: center;
  justify-content: center;
}
.sub-category .content table .actions i{
  font-size: 20px;
  padding: 1rem;
  background-color: #e9f3ef;
  border-radius: 10px;
  cursor: pointer;
}
.sub-category .content table .actions i:nth-of-type(2){
  margin: 0 .5rem;
}
.sub-category .content table .actions i:hover{
  color: #6F825F;
}

.sub-category .content table{
  font-size: 14px;
}
</style>
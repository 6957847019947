<template>
  <div class="login" id="login">
    <div class="headerImage">
      <div class="overlay"></div>
      <div class="shape">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 1440 120"
        >
          <path
            d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"
          ></path>
        </svg>
      </div>
    </div>
    <div class="loginContent">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center brand">
              <router-link to="/" class="d-inline-block">
                <img src="@/assets/logo.svg" alt="" height="20" />
              </router-link>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <form class="card" @submit.prevent="login">
              <div class="card-body">
                <div class="head text-center mt-2">
                  <h5 class="color-strong">Welcome Back!</h5>
                  <p>Sign in to continue to tiny koala admin panel.</p>
                </div>
                <div class="mb-3">
                  <label class="form-label"
                    >Email <span class="text-danger"> *</span></label
                  >
                  <input
                    class="form-control"
                    type="email"
                    placeholder="Please enter email"
                    v-model="loginData.email"
                    required
                  />
                </div>
                <div class="mb-3">
                  <label class="form-label">Password </label
                  ><span class="text-danger"> *</span>
                  <input
                    class="form-control"
                    type="password"
                    placeholder="Enter password"
                    v-model="loginData.password"
                    required
                  />
                </div>
                <div class="mt-4">
                  <input class="btn w-100 background" type="submit" value="Sign In" :disabled="isLoading ? true:false" />
                </div>
                <p class="error-message">{{ errorMessage }}</p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <AppFooter />
  </div>
</template>

<script>
import AppFooter from "@/components/global/AppFooter.vue";
import store from '@/store'

export default {
  name: "LoginView",
  data() {
    return {
      loginData: {
        email: '',
        password: '',
      },
      errorMessage: '',
      isLoading: false
    };
  },
  methods: {
    login() {
      this.isLoading = true;      
      fetch(this.serverURL + 'api/auth/login',{
        method:  'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: this.loginData.email,
          password: this.loginData.password,
        })
      })
        .then(response => response.json())
        .then(data => {
          if (data.status == 200) {
            localStorage.setItem('admin_token', data.token);
            store.state.accessToken = localStorage.getItem('admin_token');

            localStorage.setItem('admin_info', JSON.stringify(data.data));
            store.state.adminInfo = JSON.parse(localStorage.getItem('admin_info'));

            this.$router.push({ name: "Dashboard"});
          }else{
            this.errorMessage = data.message;
          }
          this.isLoading = false;
        })
        .catch(error => {
          console.error(error);
        })
    }
  },
  components: {
    AppFooter,
  },
};
</script>

<style>
.login {
  padding-top: 5rem ;
  background: #f1f1f1 ;
  min-height: 100vh;
}
/* For Header Image */
.login .headerImage {
  background-image: url('@/assets/images/auth/loginImage.jpg');
  background-position: center;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 380px;
}
.login .headerImage .overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
  background: linear-gradient(to right, #000, #8a9d78);
  opacity: 0.9;
}
.login .headerImage .shape {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
  pointer-events: none;
}

.login .headerImage .shape > svg {
  width: 100%;
  height: auto;
  fill: #f1f1f1;
}
/* For Content And Form */
.login .loginContent {
  position: relative;
  z-index: 2;
  width: 100%;
}
.login .loginContent .brand {
  margin-bottom: 1.5rem ;
}
.login .loginContent .brand img{
  width: 5rem;
  height: 5rem;
}
.login .loginContent .brand p {
  font-size: 13px;
  font-weight: 500;
  margin-top: 1rem;
}
.login .loginContent .card .card-body {
  padding: 1.5rem;
}
.login .loginContent .card .card-body h5 {
  font-weight: 600;
}
.login .loginContent .card .card-body .head p {
  color: #878a99;
}
.login .loginContent .card a {
  text-decoration: none;
  color: #878a99;
  font-size: .8rem;
}
.login .loginContent .card .card-body .mb-3{
  text-align: left;
}
.login .loginContent .card .card-body label {
  font-weight: 500;
  font-size: .8rem;
}
.login .loginContent .card .card-body input {
  outline: none;
}
.login .loginContent .card .card-body input::placeholder{
  font-size: .8rem; 
}
.login .loginContent .card .card-body input:focus {
  box-shadow: none ;
  border: 1px solid #ccc ;
}
.login .loginContent .card .card-body .form-check {
  margin: 2vh 0;
}
.login .loginContent .card .card-body input[type='submit'] {
  color: #fff ;
}
.login .loginContent .card .card-body form ~ div p {
  color: #000;
}
.login .loginContent .card .card-body form ~ div a {
  color: #5ea3cb;
  font-weight: 600;
}
.login p{
  font-size: .8rem;
}
</style>

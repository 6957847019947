<template>
  <div class="contact-us" id="contact-us">
    <div class="temp"></div>
    <div class="content">
      <Loading v-model:active="isLoading"
                 :can-cancel="true"
                 :is-full-page="false"
                 color="#B2CC9B"
                 loader="bars"
                 background-color="#000"
                 :lock-scroll="false"
                 />
      <div class="title">
        <h2 class="color-strong">Messages Management</h2>
      </div>
      <table>
        <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Date/Time</th>
            <th scope="col">Full Name</th>
            <th scope="col">Email</th>
            <th scope="col">Mobile Number</th>
            <th scope="col">Status</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr class="record" v-for="request in requests" :key="request.id" :data-id="request.id" :data-message="request.message">
            <td data-label="ID">{{ request.id }}</td>
            <td data-label="Date/Time">{{ request.created_at.substring(0, 10) }}<br/>{{ request.created_at.substring(11, 19) }}</td>
            <td data-label="Full Name">{{ request.name }}</td>
            <td data-label="Email">{{ request.email }}</td>
            <td data-label="Mobile Number">{{ request.phone }}</td>
            <td data-label="Status">
              <div class="status">
                <label class="switch">
                  <input type="checkbox" :checked="request.status != 'PENDING'" />
                  <span class="slider round"></span>
                </label>
              </div>
            </td>
            <td data-label="Actions">
              <div class="actions">
                <v-icon icon="mdi-eye-outline" class="view"
                  data-bs-toggle="modal" data-bs-target="#viewRequestModal" />
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="7">
              <v-icon icon="mdi-chevron-left" class="icon previous" />
              <span>{{ currPage }} - {{ totalPages }}</span>
              <v-icon icon="mdi-chevron-right" class="icon next" />
            </td>
          </tr>
        </tfoot>
      </table>
      
      <view-request-modal :message="cardMessage" />
    </div>
  </div>
</template>

<script>
// Loading
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

import store from '@/store'

import ViewRequestModal from '@/views/ContactUs/ViewRequestModal.vue'

export default {
  name: "ContactUsView",
  data() {
    return {
      requests: [],
      currPage: 1,
      totalPages: 0,
      cardMessage: '',
      isLoading: false,
    }
  },
  methods: {
    getMessages() {
      this.isLoading = true;

      fetch(this.serverURL + 'api/messages?page=' + this.currPage, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          this.isLoading = false;
          this.requests = data.data;
          
          // Meta
          if(data.meta.total > 0){
            this.currPage = data.meta.currentPage;
            this.totalPages = Math.ceil(data.meta.total / data.meta.perPage);
          }else{
            this.currPage = 0;
            this.totalPages = 0;
          }
        })
        .catch(error => {
          console.log(error);
        })
    },
    seeMessage(id) {
      fetch(this.serverURL + 'api/messages/' + id + '/see',{
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
        .then(response => response.json())
        .then()
        .catch()
    },
    unseeMessage(id) {
      fetch(this.serverURL + 'api/messages/' + id + '/unsee',{
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
        .then(response => response.json())
        .then()
        .catch()
    },
  },
  beforeMount() {
    this.getMessages();
  },
  mounted(){
    let that = this;

    // On Checkbox change
    window.$('body').on('change', '.contact-us .content table .status .switch input[type="checkbox"]', function() {
      if (this.checked){
        that.seeMessage(window.$(this).parents('.record').data('id'));
      }else{
        that.unseeMessage(window.$(this).parents('.record').data('id'));
      }
    });

    // On View Clicked
    window.$('body').on('click', '.contact-us .content table .actions .view', function() {
      that.cardMessage = window.$(this).parents('.record').data('message');
    });

    // On Previous Clicked
    window.$('.contact-us .content table .previous').on('click', function() {
      if(that.currPage > 1){
        that.currPage -= 1;
        that.getMessages();
      }
    });

    // On Next Clicked
    window.$('.contact-us .content table .next').on('click', function() {      
      if(that.currPage < that.totalPages){
        that.currPage += 1;
        that.getMessages();
      }
    });
  },
  components: {
    ViewRequestModal,
    Loading
  },
};
</script>

<style>
.contact-us{
  display: flex;
}

/* Temp  */
.contact-us .temp{
  min-width: 250px;
  transition: all .5s;
}
.close-rail .contact-us .temp{
  min-width: 70px;
  transition: all .5s;
}

/* Content */
.contact-us .content{
  position: relative;
  width: -webkit-fill-available;
  background-color: #F1F1F1;
  min-height: 89vh;
  padding: 2rem;
  text-align: left;
}
@media(max-width: 576px){
  .contact-us .content{
    padding: 1rem .5rem;
  }
}
.contact-us .content .title{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}
.contact-us .content .title h2{
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 0;
}

/* Table */
.contact-us .content table img{
  width: 5rem;
}
.contact-us .content table .actions{
  display: flex;
  align-items: center;
  justify-content: center;
}
.contact-us .content table .actions i{
  font-size: 20px;
  padding: 1rem;
  background-color: #e9f3ef;
  border-radius: 10px;
  cursor: pointer;
}
.contact-us .content table .actions i:hover{
  color: #6F825F;
}

.contact-us .content table{
  font-size: 14px;
}
</style>
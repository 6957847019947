<template>
  <div class="product-details" id="product-details">
    <div class="temp"></div>
    <div class="content">
      <Loading v-model:active="isLoading"
                 :can-cancel="true"
                 :is-full-page="false"
                 color="#B2CC9B"
                 loader="bars"
                 background-color="#000"
                 :lock-scroll="false"
                 />
      <div class="title">
        <h2 class="color-strong">Product Details</h2>
        <router-link class="color" to="/Product">Back</router-link>
      </div>
      <div class="row">
        <div class="col-md-5">
          <div class="images">
            <img :src="info.image" alt="Product Image">
            <div class="group">
              <div class="row">
                <div class="col-3">
                  <img src="@/assets/images/product.png" alt="Product Image">
                </div>
                <div class="col-3">
                  <img src="@/assets/images/product.png" alt="Product Image">
                </div>
                <div class="col-3">
                  <img src="@/assets/images/product.png" alt="Product Image">
                </div>
                <div class="col-3">
                  <img src="@/assets/images/product.png" alt="Product Image">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-7">
          <div class="details">
            <h2>{{ info.name }}</h2>
            <div class="prices">
              <h3>{{ info.offer_price?info.offer_price:info.price }} KWD</h3>
              <h3 class="old-price">{{ info.offer_price?info.price + ' KWD':'' }}</h3>
            </div>
            <div class="selections">
              <div class="category row">
                <h4 class="col-6">Category</h4>
                <span class="col-6">{{ info.category?info.category.parent?info.category.parent.name:info.category.name:'' }}</span>
              </div>
              <div class="subcategory row">
                <h4 class="col-6">Subcategory</h4>
                <span class="col-6">{{ info.category?info.category.parent?info.category.name:'-':'' }}</span>
              </div>
              <div class="status row">
                <h4 class="col-6">Status</h4>
                <span v-if="info.status == 'ACTIVE'" class="active col-6">{{ info.status }}</span>
                <span v-else class="deactive col-6">{{ info.status }}</span>
              </div>
              <div class="sizes">
                <h4>Size</h4>
                <div class="box">
                  <span v-for="size in info.sizes" :key="size.id">{{ size.name }}</span>
                </div>
              </div>
              <div class="colors">
                <h4>Color</h4>
                <div class="box">
                  <span v-for="color in info.colors" :key="color.id">{{ color.name }}</span>
                </div>
              </div>
              <div class="combination" v-if="info.product_color_size && info.product_color_size.length > 0">
                <h4>Combination</h4>
                <table class="no-cards">
                  <thead>
                    <tr>
                      <th scope="col">Color</th>
                      <th scope="col">Size</th>
                      <th scope="col">Stock</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="record" v-for="comb in info.product_color_size" :key="comb.id">
                      <td data-label="Color">{{ comb.color?comb.color.name:'-' }}</td>
                      <td data-label="Size">{{ comb.size?comb.size.name:'-' }}</td>
                      <td data-label="Stock">{{ comb.stock }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="description">
                <h4>Description</h4>
                <p>{{ info.description }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Loading
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

import store from '@/store'

export default {
  name: "ProductDetailsView",
  props: {
    name: String
  },
  data() {
    return {
      info: {},
      isLoading: false,
    }
  },
  methods: {
    getInfo() {
      fetch(this.serverURL + 'api/catalog/products/' + localStorage.getItem('product_id'), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          this.info = data.data;
        })
        .catch(error => {
          console.log(error);
        })
    },
  },
  beforeMount() {
    this.getInfo();
  },
  mounted(){
  },
  components: {
    Loading
  },
};
</script>

<style>
.product-details{
  display: flex;
}

/* Temp  */
.product-details .temp{
  min-width: 250px;
  transition: all .5s;
}
.close-rail .product-details .temp{
  min-width: 70px;
  transition: all .5s;
}

/* Content */
.product-details .content{
  position: relative;
  width: -webkit-fill-available;
  background-color: #F1F1F1;
  min-height: 89vh;
  padding: 2rem;
  text-align: left;
}
@media(max-width: 576px){
  .product-details .content{
    padding: 1rem .5rem;
  }
}
.product-details .content .title{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}
.product-details .content .title h2{
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 0;
}
.product-details .content .title a{
  cursor: pointer;
  padding: .7rem 2rem;
  border-radius: 10px;
  font-size: 16px;
  font-weight: bold;
}

/* Images */
.product-details .content .images img{
  width: 100%;
}
.product-details .content .images .group{
  margin-top: 1rem;
}

/* Details */
.product-details .content .details h2{
  font-size: 25px;
  color: black;
  font-weight: bold;
  margin-bottom: 0;
}
@media(max-width: 767px){
  .product-details .content .details{
    margin-top: 2rem;
  }
}
.product-details .content .details .prices{
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 2rem 0;
}
.product-details .content .details .prices h3{
  font-size: 20px;
  margin-bottom: 0;
}
.product-details .content .details .prices h3:first-of-type{
  color: #6F825F;
  font-weight: bold;
  margin-right: 1rem;
}
.product-details .content .details .prices h3.old-price{
  color: #A2A2A2;
  text-decoration: line-through;
}
.product-details .content .details .selections > div:not(:first-of-type){
  margin-top: 2rem;
}
.product-details .content .details .selections h4{
  color: #313131;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: .5rem;
}
.product-details .content .details .selections .category h4,
.product-details .content .details .selections .subcategory h4,
.product-details .content .details .selections .status h4{
  margin-bottom: 0;
}
.product-details .content .details .selections div > span{
  font-size: 15px;
  color: #444444;
}
.product-details .content .details .selections div > span.active,
.product-details .content .details .selections div > span.deactive{
  font-weight: bold;
}
.product-details .content .details .selections div > span.active{
  color: green;
}
.product-details .content .details .selections div > span.deactive{
  color: red;
}
.product-details .content .details .selections .box{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.product-details .content .details .selections .box span{
  font-size: 15px;
  color: #FFF;
  background-color: #B2CC9B;
  border: 1px solid #B2CC9B;
  padding: .5rem 1rem;
  border-radius: 10px;
}
.product-details .content .details .selections .box span:not(:last-of-type){
  margin-right: .5rem;
}
.product-details .content .details .selections .box span{
  margin-bottom: .5rem
}
.product-details .content .details .selections .box span.active{
  color: #FFF;
  background-color: #B2CC9B;
  border-color: #B2CC9B;
}
.product-details .content .details .selections .combination td{
  color: #444444;
  font-size: 15px;
}
.product-details .content .details .selections .combination .result td{
  font-weight: bold;
}
.product-details .content .details .selections .description p{
  color: #444444;
  font-size: 15px;
  margin-bottom: 0;
}

.product-details .content table{
  font-size: 14px;
}
</style>
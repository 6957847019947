import { reactive } from 'vue'

const store = ({
  state: reactive({
    accessToken: null,
    adminInfo: null,
    appLang: null,
    appMode: null
  }),
  getters: {
    getAccessToken() {
      return store.state.accessToken
    },
    getAdminInfo() {
      return store.state.adminInfo
    },
    getAppLang() {
      return store.state.appLang
    },
    getAppMode() {
      return store.state.appMode
    }
  },
  mutations: {
  },
  actions: {
    initializeAccessToken() {
      store.state.accessToken = localStorage.getItem('admin_token')?localStorage.getItem('admin_token'):''
    },
    initializeAdminInfo() {
      store.state.adminInfo = localStorage.getItem('admin_info')?JSON.parse(localStorage.getItem('admin_info')):''
    },
    initializeAppLang() {
      store.state.appLang = localStorage.getItem('app_lang')?localStorage.getItem('app_lang'):'en'
    },
    initializeAppMode() {
      store.state.appMode = localStorage.getItem('app_mode')?localStorage.getItem('app_mode'):'light'
    }
  }
})

export default store
<template>
  <div class="edit-product" id="edit-product">
    <!-- Modal -->
    <div
      class="modal fade"
      id="editProductModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="body">
            <div class="title">
              <h2 class="color-strong">Edit Product</h2>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                @click="closeModal"
              ></button>
            </div>
            <form @submit.prevent="UpdateData">
              <div v-if="step == 1" class="row">
                <div class="col-md-6">
                  <label>Image</label>
                  <label for="productImage" class="upload-input"
                    >Image Upload
                    <img src="@/assets/icons/upload.png" alt="Upload Icon" />
                  </label>
                  <input
                    type="file"
                    id="productImage"
                    @change="handleImageChange"
                    accept="image/*"
                    style="display: none"
                  />
                </div>
                <div class="col-md-6"></div>
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-12">
                      <label>Title*</label>
                      <input type="text" v-model="info.en_title" placeholder="Title" />
                    </div>
                    <div class="col-12">
                      <label>Description*</label>
                      <textarea v-model="info.en_description" placeholder="Description" rows="5" ></textarea>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="row ar">
                    <div class="col-12">
                      <label>العنوان*</label>
                      <input type="text" v-model="info.ar_title" placeholder="العنوان" />
                    </div>
                    <div class="col-12">
                      <label>الشرح*</label>
                      <textarea v-model="info.ar_description" placeholder="الشرح" rows="5" ></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="step == 2" class="row">
                <div class="col-md-6">
                  <label>Category*</label>
                  <v-select
                    v-model="info.category_id"
                    :items="categories"
                    item-title="name"
                    item-value="id"
                    variant="outlined"
                  ></v-select>
                </div>
                <div class="col-md-6">
                  <label>Subcategory*</label>
                  <v-select
                    v-model="info.subcategory_id"
                    :items="subcategories"
                    item-title="name"
                    item-value="id"
                    variant="outlined"
                    :required="subcategories.length > 0"
                  ></v-select>
                </div>
                <div class="col-md-6">
                  <label>Price*</label>
                  <input type="text" v-model="info.price" placeholder="Price" pattern="[0-9]+(.[0-9]+)?" />
                </div>
                <div class="col-md-6">
                  <label>Offer Price*</label>
                  <input type="text" v-model="info.offer_price" placeholder="Offer Price" pattern="[0-9]+(.[0-9]+)?" />
                </div>
                <div class="col-md-6">
                  <label>Start Date</label>
                  <input type="date" v-model="info.start_date" placeholder="Start Date" :required="info.offer_price != ''" />
                </div>
                <div class="col-md-6">
                  <label>End Date</label>
                  <input type="date" v-model="info.end_date" placeholder="End Date" :required="info.offer_price != ''" />
                </div>
                <div class="col-md-12">
                  <div class="row toggles">
                    <div class="col-sm-6 col-md-3">
                      <label>Best Selling</label>
                      <label class="switch">
                        <input type="checkbox" v-model="info.best_selling" >
                        <span class="slider round"></span>
                      </label>
                    </div>
                    <div class="col-sm-6 col-md-3">
                      <label>New Arrival</label>
                      <label class="switch">
                        <input type="checkbox" v-model="info.new_arrival" >
                        <span class="slider round"></span>
                      </label>
                    </div>
                    <div class="col-sm-6 col-md-3">
                      <label>Super Sale</label>
                      <label class="switch">
                        <input type="checkbox" v-model="info.super_sale" >
                        <span class="slider round"></span>
                      </label>
                    </div>
                    <div class="col-sm-6 col-md-3">
                      <label>May Like</label>
                      <label class="switch">
                        <input type="checkbox" v-model="info.featured" >
                        <span class="slider round"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="error-message">
                <p v-for="error in errorMessages" :key="error">{{ error }}</p>
              </div>      
              <div class="buttons">
                <input v-if="step != 1" type="button" value="Previous" class="prev" />
                <input v-if="step != 2" type="submit" value="Next" class="next" />            
                <input v-if="step == 2" type="submit" class="submit-btn background" value="Submit" :disabled="isLoading ? true:false" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store'

export default {
  name: "AddProductModal",
  props: ['id'],
  data() {
    return {
      info: {
        image: null,
        category_id: null,
        subcategory_id: null,
        en_title: '',
        ar_title: '',
        en_description: '',
        ar_description: '',
        price: '',
        offer_price: '',
        start_date: '',
        end_date: '',
        best_selling: false,
        new_arrival: false,
        super_sale: false,
        featured: false,
      },
      categories: [],
      subcategories: [],
      step: 2,
      firstTime: true,
      errorMessages: [],
      isLoading: false
    }
  },
  methods: {
    handleImageChange(e) {
      const selectedFile = e.target.files[0];
      this.info.image = selectedFile;
    },
    closeModal(){
      this.info = {
        image: null,
        category_id: null,
        subcategory_id: null,
        en_title: '',
        ar_title: '',
        en_description: '',
        ar_description: '',
        price: '',
        offer_price: '',
        best_selling: false,
        new_arrival: false,
        super_sale: false,
        featured: false,
      };
      this.step = 1,
      this.firstTime = true,
      this.$emit('closeModal');
    },
    getInfo() {
      fetch(this.serverURL + 'api/catalog/products/' + this.id, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          this.info.ar_title = data.data.translations[0].name;
          this.info.ar_description = data.data.translations[0].description;
          this.info.en_title = data.data.translations[1].name;
          this.info.en_description = data.data.translations[1].description;
          this.info.price = data.data.price;
          if(data.data.offer_price){
            this.info.offer_price = data.data.offer_price;
            this.info.end_date = data.data.offer_price_end;
          }
          if(data.data.best_selling)
            this.info.best_selling = true;
          if(data.data.new_arrival)
            this.info.new_arrival = true;
          if(data.data.super_sale)
            this.info.super_sale = true;
          if(data.data.featured)
            this.info.featured = true;
          if(data.data.category.parent){
            this.info.category_id = data.data.category.parent.id;
            this.info.subcategory_id = data.data.category.id;
          }else{
            this.info.category_id = data.data.category.id;
          }
        })
        .catch(error => {
          console.log(error);
        })
    },
    getCategories() {
      fetch(this.serverURL + 'api/catalog/categories', {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          this.categories = data.data;
        })
        .catch(error => {
          console.log(error);
        })
    },
    getSubcategories() {
      fetch(this.serverURL + 'api/catalog/categories/' + this.info.category_id + '/sub-categories', {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          this.subcategories = data.data;
        })
        .catch(error => {
          console.log(error);
        })
    },
    UpdateData(){
      if (this.step == 2){
        this.isLoading = true;

        let formData = new FormData();
        if(this.info.image)
          formData.append("image", this.info.image);
        if(this.info.category_id)
          formData.append("category_id", this.info.subcategory_id?this.info.subcategory_id:this.info.category_id);
        if(this.info.price)
          formData.append("price", this.info.price);
        if(this.info.offer_price){
          formData.append("offer_price", this.info.offer_price);
          formData.append("offer_price_end", this.info.end_date);
        }
        formData.append("best_selling", this.info.best_selling?1:0);
        formData.append("new_arrival", this.info.new_arrival?1:0);
        formData.append("super_sale", this.info.super_sale?1:0);
        formData.append("featured", this.info.featured?1:0);
        if(this.info.en_title)
          formData.append("en[name]", this.info.en_title);
        if(this.info.ar_title)
          formData.append("ar[name]", this.info.ar_title);
        if(this.info.en_description)
          formData.append("en[description]", this.info.en_description);
        if(this.info.ar_description)
          formData.append("ar[description]", this.info.ar_description);
        formData.append("_method", "PUT");

        fetch(this.serverURL + 'api/catalog/products/' + this.id,{
          method:  'POST',
          headers: {
            Authorization: `Bearer ${store.state.accessToken}`,
          },
          body: formData,
        })
          .then(response => response.json())
          .then(data => {
            this.isLoading = false;

            if (data.status == 200){
              location.reload();
            }else{
              let messages = [];

              for(let propt in data.errors){
                messages.push(data.errors[propt].join(' '));
              }
              this.errorMessages = messages;
            }
          })
          .catch(error => {
            console.error(error);
          })
      }
      else{
        this.step += 1;
      }
    }
  },
  beforeMount() {
    this.getCategories();
  },
  mounted(){
    let that = this;

    // On Prev Clicked
    window.$('body').on('click', '.edit-product .modal form .buttons .prev', function() {
      that.step -= 1;
    });
  },
  computed: {
    category_id(){
      return this.info.category_id;
    }
  },
  watch: {
    id: function(){
      if(this.id != -1)
        this.getInfo();
    },
    category_id: function(){
      if(this.id != -1){
        if(this.firstTime == false)
          this.info.subcategory_id = null;
        this.firstTime = false;
        this.getSubcategories();
      }
    }
  },
  components: {},
};
</script>

<style>
.edit-product .modal{
  height: 100vh;
}
.edit-product .modal .modal-content {
  border-radius: 1rem;
  box-shadow: 1px 1px 5px #856161;
  width: 35rem;
  max-height: 90vh;
  overflow: hidden;
}
.edit-product .modal .body{
  height: 100%;
  overflow: auto;
  padding: 2rem;
}
@media(max-width: 767px){
  .edit-product .modal .modal-content {
    width: initial;
  }
}
.edit-product .modal .title{
  margin-bottom: 1rem !important;
}
.edit-product .modal h2{
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 0;
}

/* Form */
.edit-product .modal form .ar{
  direction: rtl;
  text-align: right;
}
.edit-product .modal form .toggles > div{
  text-align: center;
}
.edit-product .modal form label {
  display: block;
  margin: 1rem 0 .5rem 0;
  color: black;
}
.edit-product .modal form input,
.edit-product .modal form textarea,
.edit-product .modal form .v-select,
.edit-product .modal form label.upload-input {
  border: 1px solid #CCC;
  background-color: #FFF;
  padding: .5rem 1rem;
  border-radius: 10px;
  width: 90%;
}
.edit-product .modal form label.upload-input {
  color: #6b6b6b;
  cursor: pointer;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.edit-product .modal form label.upload-input img {
  width: 1.2rem;
  height: 1.1rem;
}
.edit-product .modal form .v-select {
  outline: none;
  cursor: pointer;
}
.edit-product .modal form .generate{
  width: fit-content;
  display: block;
  border: none;
  background-color: #6b6b6b;
  box-shadow: 0 1px 5px #CCC;
  margin: auto;
  margin-top: 1rem;
  font-weight: bold;
}
.edit-product .modal form .generate:hover{
  background-color: #555151;
}
.edit-product .modal form .combination-table{
  margin-top: 2rem;
}
.edit-product .modal form .combination-table input,
.edit-product .modal form .combination-table label.upload-input{
  width: 10rem;
}
.edit-product .modal form .combination-table label.upload-input{
  background-color: #CCC;
  display: inline-flex;
  margin: 0;
}
.edit-product .modal form .buttons{
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 2rem;
}
.edit-product .modal form .buttons input {
  padding: 0.5rem 1rem;
  border-radius: 10px;
  color: #fff;
  box-shadow: 0 1px 5px #f1e7e7;
  font-weight: bold;
  display: block;
  width: 6rem;
}
.edit-product .modal form .buttons .prev,
.edit-product .modal form .buttons .next{
  background-color: #6C757D;
}

/* V-Select */
.edit-product .modal form .v-select{
  padding: 0;
}
.edit-product .modal form .v-select.v-input--disabled{
  background-color: #f3f3f3;
}
.edit-product .modal form .v-select .v-field__input{
  padding: .5rem 1rem;
  min-height: 2.8rem;
}
.edit-product .modal form .v-select .v-field{
  border-radius: 10px;
}
.edit-product .modal form .v-select span,
.edit-product .modal form .v-select .v-icon{
  color: black;
}
.edit-product .modal form .v-select input{
  border: none;
  background-color: transparent;
  padding: 0;
  border-radius: 0;
  width: 100%;
}
.edit-product .modal form .v-select label{
  margin: 0 1rem;
}
.edit-product .modal form .v-select .v-input__details{
  display: none;
}

/* Init Table */
@media(max-width: 767px) {
  .edit-product table {
    border: 0;
    box-shadow: none;
  }
  .edit-product table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  .edit-product table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .625em;
    box-shadow: 0 1px 5px #CCC;
  } 
  .edit-product table tbody td {
    border-bottom: 1px solid #ddd;
    align-items: center;
    display: flex;
    justify-content: space-between;
    font-size: .8em;
    text-align: right;
  }
  .edit-product table tfoot td{
    display: block;
  }
  .edit-product table tbody td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }
  .edit-product table tbody td:last-child {
    border-bottom: 0;
  }
}
</style>

<template>
  <div class="footer">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="text-center">
            <p class="mb-0 text-muted">
              © 2024 Tiny Koala. Powered By Linekw.com
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterComponent",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.footer {
  left: 0;
  background-color: #f1f1f1;
  color: var(--vz-body-color);
  right: 0;
  height: 60px;
  padding: 4vh 0;
}
.footer p {
  --vz-text-opacity: 1;
  color: #878a99 !important;
}
</style>

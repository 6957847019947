<template>
  <div class="about-us" id="about-us">
    <div class="temp"></div>
    <div class="content">
      <Loading v-model:active="isLoading"
                 :can-cancel="true"
                 :is-full-page="false"
                 color="#B2CC9B"
                 loader="bars"
                 background-color="#000"
                 :lock-scroll="false"
                 />
      <div class="title">
        <h2 class="color-strong">About US Content</h2>
      </div>
      <form @submit.prevent="UpdateData">
        <div class="row">
          <div class="col-md-6">
            <label>Image</label>
            <label for="image" class="upload-input"
              >Image Upload
              <img src="@/assets/icons/upload.png" alt="Upload Icon" />
            </label>
            <input
              type="file"
              id="image"
              @change="handleImageChange"
              accept="image/*"
              style="display: none"
            />
          </div>
          <div class="col-md-6 preview">
            <a v-if="image_url != ''" :href="image_url" target="_blank" class="color-strong">Image Preview</a>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-12">
                <label>Content*</label>
                <QuillEditor class="en_editor" theme="snow" v-model:content="aboutusData.en_content" toolbar="full" contentType="html" placeholder="Enter Content" />
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row ar">
              <div class="col-12">
                <label>المحتوى*</label>
                <QuillEditor class="ar_editor" theme="snow" v-model:content="aboutusData.ar_content" toolbar="full" contentType="html" placeholder="ادخل المحتوى" />
              </div>
            </div>
          </div>
        </div>
        <div class="error-message">
          <p v-for="error in errorMessages" :key="error">{{ error }}</p>
        </div>
        <input type="submit" class="submit-btn background" value="Save" :disabled="isLoading ? true:false" />
      </form>
    </div>
  </div>
</template>

<script>
// Loading
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

import store from '@/store'

// Quill Editor
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import '@vueup/vue-quill/dist/vue-quill.bubble.css';

export default {
  name: "AboutUsView",
  data() {
    return {
      aboutusData: {
        id: -1,
        en_content: '',
        ar_content: '',
        image: null,
      },
      image_url: '',
      errorMessages: [],
      isLoading: false,
    }
  },
  methods: {
    handleImageChange(e) {
      const selectedFile = e.target.files[0];
      this.aboutusData.image = selectedFile;
      this.image_url = URL.createObjectURL(selectedFile);
    },
    getInfo() {
      this.isLoading = true;

      fetch(this.serverURL + 'api/cms/pages/about-us', {
        method: "GET",
        headers: {
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          this.isLoading = false;

          this.aboutusData.id = data.data.id;
          this.aboutusData.ar_content = data.data.translations[0].content;
          window.$('.about-us .ar_editor .ql-editor').html(this.aboutusData.ar_content);
          this.aboutusData.en_content = data.data.content;
          window.$('.about-us .en_editor .ql-editor').html(this.aboutusData.en_content);
          this.image_url = data.data.image;

          console.log(this.aboutusData);
        })
        .catch(error => {
          console.log(error);
        })
    },
    UpdateData() {
      this.isLoading = true;

      let formData = new FormData();
      if (this.aboutusData.en_content)
        formData.append("en[content]", this.aboutusData.en_content);
      if (this.aboutusData.ar_content)
        formData.append("ar[content]", this.aboutusData.ar_content);
      if (this.aboutusData.image)
        formData.append("image", this.aboutusData.image);
      formData.append("_method", 'PUT');

      fetch(this.serverURL + 'api/pages/' + this.aboutusData.id,{
        method:  'POST',
        headers: {
          Authorization: `Bearer ${store.state.accessToken}`,
        },
        body: formData,
      })
        .then(response => response.json())
        .then(data => {
          this.isLoading = false;

          if (data.status == 200){
            location.reload();
          }else{
            let messages = [];

            for(let propt in data.errors){
              messages.push(data.errors[propt].join(' '));
            }
            this.errorMessages = messages;
          }
        })
        .catch(error => {
          console.error(error);
        })
    }
  },
  beforeMount() {
    this.getInfo();
  },
  mounted(){
  },
  components: {
    Loading,
    QuillEditor
  },
};
</script>

<style>
.about-us{
  display: flex;
}

/* Temp  */
.about-us .temp{
  min-width: 250px;
  transition: all .5s;
}
.close-rail .about-us .temp{
  min-width: 70px;
  transition: all .5s;
}

/* Content */
.about-us .content{
  position: relative;
  width: -webkit-fill-available;
  background-color: #F1F1F1;
  min-height: 89vh;
  padding: 2rem;
  text-align: left;
}
@media(max-width: 576px){
  .about-us .content{
    padding: 1rem .5rem;
  }
}
.about-us .content .title{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}
.about-us .content .title h2{
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 0;
}

/* Quill Editor */
.about-us .content .ql-toolbar{
  background-color: #F3F3F9;
  border-radius: 10px 10px 0 0;
}
.about-us .content .ql-container{
  height: 10rem;
  background-color: #FFF;
  border-radius: 0 0 10px 10px;
}

/* Form */
.about-us .content form .preview{
  display: flex;
  align-items: flex-end;
}
.about-us .content form .ar label{
  direction: rtl;
  text-align: right;
}
.about-us .content form label {
  display: block;
  margin: 1rem 0 .5rem 0;
  color: black;
}
.about-us .content form input,
.about-us .content form label.upload-input {
  border: 1px solid #CCC;
  background-color: #FFF;
  padding: .5rem 1rem;
  border-radius: 10px;
  width: 90%;
}
.about-us .content form label.upload-input {
  color: #6b6b6b;
  cursor: pointer;
  display: flex;
  margin: 0;
  justify-content: space-between;
}
.about-us .content form label.upload-input img {
  width: 1.2rem;
  height: 1.1rem;
}
.about-us .content form .submit-btn {
  padding: 0.5rem 1rem;
  border-radius: 10px;
  color: #fff;
  box-shadow: 0 1px 5px #f1e7e7;
  font-weight: bold;
  display: block;
  margin-top: 2rem;
  width: 6rem;
}
</style>
<template>
  <div class="add-banner" id="add-banner">
    <!-- Modal -->
    <div
      class="modal fade"
      id="addBannerModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="body">
            <div class="title">
              <h2 class="color-strong">Add Banner</h2>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <form @submit.prevent="SendData">
              <div class="row">
                <div class="col-md-6">
                  <label>Image*</label>
                  <label for="image" class="upload-input"
                    >Image Upload
                    <img src="@/assets/icons/upload.png" alt="Upload Icon" />
                  </label>
                  <input
                    type="file"
                    id="image"
                    @change="handleImageChange"
                    accept="image/*"
                    style="display: none"
                  />
                </div>
                <div class="col-md-6"></div>
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-12">
                      <label>Title*</label>
                      <input type="text" v-model="bannerData.en_title" placeholder="Title" required/>
                    </div>
                    <div class="col-12">
                      <label>Description*</label>
                      <textarea v-model="bannerData.en_description" placeholder="Description" rows="5" required></textarea>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="row ar">
                    <div class="col-12">
                      <label>العنوان*</label>
                      <input type="text" v-model="bannerData.ar_title" placeholder="العنوان" required/>
                    </div>
                    <div class="col-12">
                      <label>الوصف*</label>
                      <textarea v-model="bannerData.ar_description" placeholder="الوصف" rows="5" required></textarea>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <label>Linked*</label>
                  <v-select
                    v-model="bannerData.linked"
                    :items="linkedTypes"
                    item-title="name"
                    item-value="value"
                    variant="outlined"
                    required
                  ></v-select>
                </div>
                <div class="col-md-6">
                  <label>To*</label>
                  <v-select
                    v-model="bannerData.to"
                    :items="items"
                    item-title="name"
                    item-value="id"
                    variant="outlined"
                    :disabled="bannerData.linked == '-'"
                    :required="bannerData.linked != '-'"
                  ></v-select>
                </div>
              </div>
              <div class="error-message">
                <p v-for="error in errorMessages" :key="error">{{ error }}</p>
              </div>            
              <input type="submit" class="submit-btn background" value="Submit" :disabled="isLoading ? true:false" />
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store'

export default {
  name: "AddBannerModal",
  data() {
    return {
      bannerData: {
        en_title: '',
        ar_title: '',
        en_description: '',
        ar_description: '',
        linked: '',
        to: '',
        image: null,
      },
      linkedTypes: [
        {
          name: 'Category',
          value: 'category'
        },
        {
          name: 'Subcategory',
          value: 'sub_category'
        },
        {
          name: 'Product',
          value: 'product'
        },
        {
          name: 'UnLinked',
          value: '-'
        }
      ],
      items: [],
      errorMessages: [],
      isLoading: false
    }
  },
  methods: {
    handleImageChange(e) {
      const selectedFile = e.target.files[0];
      this.bannerData.image = selectedFile;
    },
    getCategories() {
      this.isLoading = true;

      fetch(this.serverURL + 'api/catalog/categories', {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          this.isLoading = false;
          this.items = data.data;
        })
        .catch(error => {
          console.log(error);
        })
    },
    getSubcategories() {
      this.isLoading = true;

      fetch(this.serverURL + 'api/catalog/sub-categories', {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          this.isLoading = false;
          this.items = data.data;
        })
        .catch(error => {
          console.log(error);
        })
    },
    getProducts() {
      this.isLoading = true;

      fetch(this.serverURL + 'api/catalog/products', {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          this.isLoading = false;
          this.items = data.data;
        })
        .catch(error => {
          console.log(error);
        })
    },
    SendData() {
      this.isLoading = true;

      let formData = new FormData();
      if (this.bannerData.image)
        formData.append("image", this.bannerData.image);
      formData.append("en[title]", this.bannerData.en_title);
      formData.append("ar[title]", this.bannerData.ar_title);
      formData.append("en[description]", this.bannerData.en_description);
      formData.append("ar[description]", this.bannerData.ar_description);
      if (this.bannerData.linked != '-'){
        formData.append("sliderable_type", this.bannerData.linked);
        formData.append("sliderable_id", this.bannerData.to);
      }

      fetch(this.serverURL + 'api/sliders',{
        method:  'POST',
        headers: {
          Authorization: `Bearer ${store.state.accessToken}`,
        },
        body: formData,
      })
        .then(response => response.json())
        .then(data => {
          this.isLoading = false;

          if (data.status == 201){
            location.reload();
          }else{
            let messages = [];

            for(let propt in data.errors){
              messages.push(data.errors[propt].join(' '));
            }
            this.errorMessages = messages;
          }
        })
        .catch(error => {
          console.error(error);
        })
    }
  },
  computed: {
    linked() {
      return this.bannerData.linked;
    }
  },
  watch: {
    linked: function(){
      this.items = [];
      this.bannerData.to = '';
      if(this.linked == 'category')
        this.getCategories();
      else if(this.linked == 'sub_category')
        this.getSubcategories();
      else if(this.linked == 'product')
        this.getProducts();
    }
  },
  components: {},
};
</script>

<style>
.add-banner .modal{
  height: 100vh;
}
.add-banner .modal .modal-content {
  border-radius: 1rem;
  box-shadow: 1px 1px 5px #856161;
  width: 35rem;
  max-height: 90vh;
  overflow: hidden;
}
.add-banner .modal .body{
  height: 100%;
  overflow: auto;
  padding: 2rem;
}
@media(max-width: 767px){
  .add-banner .modal .modal-content {
    width: initial;
  }
}
.add-banner .modal .title{
  margin-bottom: 1rem !important;
}
.add-banner .modal h2{
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 0;
}

/* Form */
.add-banner .modal form .ar{
  direction: rtl;
  text-align: right;
}
.add-banner .modal form label {
  display: block;
  margin: 1rem 0 .5rem 0;
  color: black;
}
.add-banner .modal form input,
.add-banner .modal form textarea,
.add-banner .modal form .v-select,
.add-banner .modal form label.upload-input {
  border: 1px solid #CCC;
  background-color: #FFF;
  padding: .5rem 1rem;
  border-radius: 10px;
  width: 90%;
}
.add-banner .modal form label.upload-input {
  color: #6b6b6b;
  cursor: pointer;
  display: flex;
  margin: 0;
  justify-content: space-between;
}
.add-banner .modal form label.upload-input img {
  width: 1.2rem;
  height: 1.1rem;
}
.add-banner .modal form .submit-btn {
  padding: 0.5rem 1rem;
  border-radius: 10px;
  color: #fff;
  box-shadow: 0 1px 5px #f1e7e7;
  font-weight: bold;
  display: block;
  margin-top: 2rem;
  width: 6rem;
}

/* V-Select */
.add-banner .modal form .v-select{
  padding: 0;
}
.add-banner .modal form .v-select.v-input--disabled{
  background-color: #f3f3f3;
}
.add-banner .modal form .v-select .v-field__input{
  padding: .5rem 1rem;
  min-height: 2.8rem;
}
.add-banner .modal form .v-select .v-field{
  border-radius: 10px;
}
.add-banner .modal form .v-select span,
.add-banner .modal form .v-select .v-icon{
  color: black;
}
.add-banner .modal form .v-select input{
  border: none;
  background-color: transparent;
  padding: 0;
  border-radius: 0;
  width: 100%;
}
.add-banner .modal form .v-select label{
  margin: 0 1rem;
}
.add-banner .modal form .v-select .v-input__details{
  display: none;
}
</style>

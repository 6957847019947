<template>
  <div class="refund" id="refund">
    <div class="temp"></div>
    <div class="content">
      <Loading v-model:active="isLoading"
               :can-cancel="true"
               :is-full-page="false"
               color="#B2CC9B"
               loader="bars"
               background-color="#000"
               :lock-scroll="false"
      />
      <div class="title">
        <h2 class="color-strong">Refund & Exchange Requests</h2>
        <div class="filter">
          <Datepicker
              class="date-picker"
              v-model="date"
              placeholder='Enter Date'
              format="yyyy-MM-dd"
              range
          ></Datepicker>
        </div>
      </div>
      <table>
        <thead>
        <tr>
          <th scope="col">ID</th>
          <th scope="col">Date/Time</th>
          <th scope="col">Order ID</th>
          <th scope="col">Full Name</th>
          <th scope="col">Email</th>
          <th scope="col">Mobile number</th>
          <th scope="col">Type</th>
          <th scope="col">Return Reason</th>
          <th scope="col">Seen</th>
          <th scope="col">Actions</th>
        </tr>
        </thead>
        <tbody>
        <tr class="record" v-for="refund_request in refund_requests" :key="refund_request.id"
            :data-id="refund_request.id" :data-message="refund_request.message">
          <td data-label="ID">{{ refund_request.id }}</td>
          <td data-label="Date/Time">{{
              refund_request.created_at.substring(0, 10)
            }}<br/>{{ refund_request.created_at.substring(11, 19) }}
          </td>
          <td data-label="Order ID">{{ refund_request.order_id }}</td>
          <td data-label="Full Name">{{ refund_request.user.name }}</td>
          <td data-label="Email">{{ refund_request.user.email }}</td>
          <td data-label="Mobile number">{{ refund_request.user.phone }}</td>
          <td data-label="Type">{{ refund_request.type }}</td>
          <td data-label="Return Reason">{{ refund_request.reason }}</td>
          <td data-label="Status">
            <div class="status">
              <label class="switch">
                <input
                    type="checkbox"
                    :checked="refund_request.seen === 'true'"
                    @change="changeStatus(refund_request.id, refund_request.type)"
                />
                <span class="slider round"></span>
              </label>
            </div>
          </td>
          <td data-label="Actions">
            <div class="actions">
              <v-icon icon="mdi-eye-outline" class="view"/>
            </div>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <td colspan="10">
            <v-icon icon="mdi-chevron-left" class="icon previous"/>
            <span>{{ currPage }} - {{ totalPages }}</span>
            <v-icon icon="mdi-chevron-right" class="icon next"/>
          </td>
        </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>

<script>
// Loading
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

import store from '@/store'

// Date Picker
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'


export default {
  name: "OrderView",
  data() {
    return {
      date: null,
      processedDate: '',
      currPage: 1,
      refund_requests: [],
      totalPages: 1,
      isLoading: false,
    }
  },
  methods: {
    getRequests() {
      this.isLoading = true;

      console.log(this.date);
      let url = '';
      if (this.date)
        url = this.serverURL + 'api/requests?page=' + this.currPage + '&filter[created_at]=' + this.processedDate;
      else
        url = this.serverURL + 'api/requests?page=' + this.currPage;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
          .then(response => response.json())
          .then(data => {
            this.isLoading = false;
            this.refund_requests = data.data;

            // Meta
            if (data.meta.total > 0) {
              this.currPage = data.meta.currentPage;
              this.totalPages = Math.ceil(data.meta.total / data.meta.perPage);
            } else {
              this.currPage = 0;
              this.totalPages = 0;
            }
          })
          .catch(error => {
            console.log(error);
          })
    },
    changeStatus(id, type) {

      let formData = new FormData();
      let request_name = 'refund-requests';
      if (type === 'refund') {
        request_name = 'refund-requests/';
      } else {
        request_name = 'exchange-requests/';
      }
      fetch(this.serverURL + 'api/' + request_name + id + '/toggle-see', {
        method: "POST",
        headers: {
          Authorization: `Bearer ${store.state.accessToken}`,
        },
        body: formData,
      })
          .then(response => response.json())
          .then(data => {
            console.log(data);
          })
          .catch()
    }
  },
  beforeMount() {
    this.getRequests();
  },
  mounted() {
    let that = this;

   // On View Clicked
    window.$('body').on('click', '.refund .content table .actions .view', function () {
      localStorage.setItem('request_id', window.$(this).parents('.record').data('id'));
      that.$router.push({path: '/RefundDetails/'});
    });

    // On Previous Clicked
    window.$('.refund .content table .previous').on('click', function () {
      if (that.currPage > 1) {
        that.currPage -= 1;
        this.getRequests();
      }
    });

    // On Next Clicked
    window.$('.refund .content table .next').on('click', function () {
      if (that.currPage < that.totalPages) {
        that.currPage += 1;
        this.getRequests();
      }
    });
  },
  watch: {
    date: function () {
      if (this.date) {
        let string = '';
        string += this.date[0].toISOString().substring(0, 10);
        if (this.date[1]) {
          string += ','
          string += this.date[1].toISOString().substring(0, 10);
        }
        this.processedDate = string;
      }
      this.getRequests();
    },
  },
  components: {
    Datepicker,
    Loading
  },
};
</script>

<style>

/* Filter */
.order .content .filter {
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.order .content .filter > span {
  color: black;
  font-weight: bold;
  font-size: 20px;
}

.refund {
  display: flex;
}

/* Temp  */
.refund .temp {
  min-width: 250px;
  transition: all .5s;
}

.close-rail .refund .temp {
  min-width: 70px;
  transition: all .5s;
}

/* Content */
.refund .content {
  position: relative;
  width: -webkit-fill-available;
  background-color: #F1F1F1;
  min-height: 89vh;
  padding: 2rem;
  text-align: left;
}

@media (max-width: 576px) {
  .refund .content {
    padding: 1rem .5rem;
  }
}

.refund .content .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}

.refund .content .title h2 {
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 0;
}

/* Table */
.refund .content table img {
  width: 5rem;
}

.refund .content table .actions {
  display: flex;
  align-items: center;
  justify-content: center;
}

.refund .content table .actions i {
  font-size: 20px;
  padding: 1rem;
  background-color: #e9f3ef;
  border-radius: 10px;
  cursor: pointer;
}

.refund .content table .actions i:hover {
  color: #6F825F;
}

.refund .content table {
  font-size: 14px;
}
</style>
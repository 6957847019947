<template>
  <div class="edit-banner" id="edit-banner">
    <!-- Modal -->
    <div
      class="modal fade"
      id="editBannerModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="body">
            <div class="title">
              <h2 class="color-strong">Edit Banner</h2>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                @click="closeModal"
              ></button>
            </div>
            <form @submit.prevent="updateData">
              <div class="row">
                <div class="col-md-6">
                  <label>Image</label>
                  <label for="bannerImage" class="upload-input"
                    >Image Upload
                    <img src="@/assets/icons/upload.png" alt="Upload Icon" />
                  </label>
                  <input
                    type="file"
                    id="bannerImage"
                    @change="handleImageChange"
                    accept="image/*"
                    style="display: none"
                  />
                </div>
                <div class="col-md-6"></div>
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-12">
                      <label>Title</label>
                      <input type="text" v-model="info.en_title" placeholder="Title"/>
                    </div>
                    <div class="col-12">
                      <label>Description</label>
                      <textarea v-model="info.en_description" placeholder="Description" rows="5"></textarea>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="row ar">
                    <div class="col-12">
                      <label>العنوان</label>
                      <input type="text" v-model="info.ar_title" placeholder="العنوان"/>
                    </div>
                    <div class="col-12">
                      <label>الوصف</label>
                      <textarea v-model="info.ar_description" placeholder="الوصف" rows="5"></textarea>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <label>Linked</label>
                  <v-select
                    v-model="info.linked"
                    :items="linkedTypes"
                    item-title="name"
                    item-value="value"
                    variant="outlined"
                  ></v-select>
                </div>
                <div class="col-md-6">
                  <label>To</label>
                  <v-select
                    v-model="info.to"
                    :items="items"
                    item-title="name"
                    item-value="id"
                    variant="outlined"
                    :disabled="info.linked == '-'"
                    :required="info.linked != '-'"
                  ></v-select>
                </div>
              </div>
              <div class="error-message">
                <p v-for="error in errorMessages" :key="error">{{ error }}</p>
              </div>            
              <input type="submit" class="submit-btn background" value="Submit" :disabled="isLoading ? true:false" />
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store'

export default {
  name: "AddBannerModal",
  props: ['id'],
  data() {
    return {
      info: {
        en_title: '',
        ar_title: '',
        en_description: '',
        ar_description: '',
        linked: '',
        to: '',
        image: null,
      },
      linkedTypes: [
        {
          name: 'Category',
          value: 'category'
        },
        {
          name: 'Subcategory',
          value: 'sub_category'
        },
        {
          name: 'Product',
          value: 'product'
        },
        {
          name: 'UnLinked',
          value: '-'
        }
      ],
      items: [],
      firstTime: true,
      errorMessages: [],
      isLoading: false
    }
  },
  methods: {
    handleImageChange(e) {
      const selectedFile = e.target.files[0];
      this.info.image = selectedFile;
    },
    closeModal(){
      this.info = {
        en_title: '',
        ar_title: '',
        en_description: '',
        ar_description: '',
        linked: '',
        to: '',
        image: null,
      };
      this.firstTime = true;
      this.$emit('closeModal');
    },
    getInfo() {
      fetch(this.serverURL + 'api/sliders/' + this.id, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          this.info.ar_title = data.data.translations[0].title;
          this.info.en_title = data.data.translations[1].title;
          this.info.ar_description = data.data.translations[0].description;
          this.info.en_description = data.data.translations[1].description;
          if(data.data.sliderable_type){
            this.info.linked = data.data.sliderable_type;
            this.info.to = data.data.sliderable_id;
          }else{
            this.info.linked = '-';
          }
        })
        .catch(error => {
          console.log(error);
        })
    },
    getCategories() {
      this.isLoading = true;

      fetch(this.serverURL + 'api/catalog/categories', {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          this.isLoading = false;
          this.items = data.data;
        })
        .catch(error => {
          console.log(error);
        })
    },
    getSubcategories() {
      this.isLoading = true;

      fetch(this.serverURL + 'api/catalog/sub-categories', {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          this.isLoading = false;
          this.items = data.data;
        })
        .catch(error => {
          console.log(error);
        })
    },
    getProducts() {
      this.isLoading = true;

      fetch(this.serverURL + 'api/catalog/products', {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          this.isLoading = false;
          this.items = data.data;
        })
        .catch(error => {
          console.log(error);
        })
    },
    updateData() {
      this.isLoading = true;

      let formData = new FormData();
      if (this.info.image)
        formData.append("image", this.info.image);
      if (this.info.en_title)
        formData.append("en[title]", this.info.en_title);
      if (this.info.ar_title)
        formData.append("ar[title]", this.info.ar_title);
      if (this.info.en_description)
        formData.append("en[description]", this.info.en_description);
      if (this.info.ar_description)
        formData.append("ar[description]", this.info.ar_description);
      if (this.info.linked != '-'){
        formData.append("sliderable_type", this.info.linked);
        formData.append("sliderable_id", this.info.to);
      }
      formData.append("_method", 'PUT');

      fetch(this.serverURL + 'api/sliders/' + this.id,{
        method:  'POST',
        headers: {
          Authorization: `Bearer ${store.state.accessToken}`,
        },
        body: formData,
      })
        .then(response => response.json())
        .then(data => {
          this.isLoading = false;

          if (data.status == 200){
            location.reload();
          }else{
            let messages = [];

            for(let propt in data.errors){
              messages.push(data.errors[propt].join(' '));
            }
            this.errorMessages = messages;
          }
        })
        .catch(error => {
          console.error(error);
        })
    }
  },
  computed: {
    linked() {
      return this.info.linked;
    }
  },
  watch: {
    linked: function(){
      if(this.firstTime == false){
        this.items = [];
        this.info.to = '';
      }
      this.firstTime = false;
      if(this.linked == 'category')
        this.getCategories();
      else if(this.linked == 'sub_category')
        this.getSubcategories();
      else if(this.linked == 'product')
        this.getProducts();
    },
    id: function(){
      if(this.id != -1)
        this.getInfo();
    }
  },
  components: {},
};
</script>

<style>
.edit-banner .modal{
  height: 100vh;
}
.edit-banner .modal .modal-content {
  border-radius: 1rem;
  box-shadow: 1px 1px 5px #856161;
  width: 35rem;
  max-height: 90vh;
  overflow: hidden;
}
.edit-banner .modal .body{
  height: 100%;
  overflow: auto;
  padding: 2rem;
}
@media(max-width: 767px){
  .edit-banner .modal .modal-content {
    width: initial;
  }
}
.edit-banner .modal .title{
  margin-bottom: 1rem !important;
}
.edit-banner .modal h2{
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 0;
}

/* Form */
.edit-banner .modal form .ar{
  direction: rtl;
  text-align: right;
}
.edit-banner .modal form label {
  display: block;
  margin: 1rem 0 .5rem 0;
  color: black;
}
.edit-banner .modal form input,
.edit-banner .modal form textarea,
.edit-banner .modal form .v-select,
.edit-banner .modal form label.upload-input {
  border: 1px solid #CCC;
  background-color: #FFF;
  padding: .5rem 1rem;
  border-radius: 10px;
  width: 90%;
}
.edit-banner .modal form label.upload-input {
  color: #6b6b6b;
  cursor: pointer;
  display: flex;
  margin: 0;
  justify-content: space-between;
}
.edit-banner .modal form label.upload-input img {
  width: 1.2rem;
  height: 1.1rem;
}
.edit-banner .modal form .submit-btn {
  padding: 0.5rem 1rem;
  border-radius: 10px;
  color: #fff;
  box-shadow: 0 1px 5px #f1e7e7;
  font-weight: bold;
  display: block;
  margin-top: 2rem;
  width: 6rem;
}

/* V-Select */
.edit-banner .modal form .v-select{
  padding: 0;
}
.edit-banner .modal form .v-select.v-input--disabled{
  background-color: #f3f3f3;
}
.edit-banner .modal form .v-select .v-field__input{
  padding: .5rem 1rem;
  min-height: 2.8rem;
}
.edit-banner .modal form .v-select .v-field{
  border-radius: 10px;
}
.edit-banner .modal form .v-select span,
.edit-banner .modal form .v-select .v-icon{
  color: black;
}
.edit-banner .modal form .v-select input{
  border: none;
  background-color: transparent;
  padding: 0;
  border-radius: 0;
  width: 100%;
}
.edit-banner .modal form .v-select label{
  margin: 0 1rem;
}
.edit-banner .modal form .v-select .v-input__details{
  display: none;
}
</style>

<template>
  <div class="view-request" id="view-request">
    <!-- Modal -->
    <div
      class="modal fade"
      id="viewRequestModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="title">
            <h2 class="color-strong">Message Details</h2>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <p>{{ message }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ViewRequestModal",
  props: ['message'],
  data() {
    return {
    }
  },
  methods: {
  },
  components: {},
};
</script>

<style>
.view-request .modal .modal-content {
  border-radius: 1rem;
  box-shadow: 1px 1px 5px #856161;
  padding: 2rem;
}
.view-request .modal .title{
  margin-bottom: 1rem !important;
}
.view-request .modal h2{
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 0;
}
.view-request .modal p{
  margin-bottom: 0;
  color: black;
}

</style>

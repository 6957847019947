<template>
  <div class="customer-orders" id="customer-orders">
    <div class="temp"></div>
    <div class="content">
      <Loading v-model:active="isLoading"
                 :can-cancel="true"
                 :is-full-page="false"
                 color="#B2CC9B"
                 loader="bars"
                 background-color="#000"
                 :lock-scroll="false"
                 />
      <div class="title">
        <h2 class="color-strong">Customer Orders</h2>
        <router-link class="color" to="/Customer">Back</router-link>
      </div>
      <table>
        <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Date/Time</th>
            <th scope="col">Deliver Charge</th>
            <th scope="col">Total Amount</th>
            <th scope="col">Status</th>
            <th scope="col">Refund Reference</th>
          </tr>
        </thead>
        <tbody>
          <tr class="record" v-for="order in orders" :key="order.id" :data-id="order.id" :data-name="order.user?order.user.name:''">
            <td data-label="ID">{{ order.id }}</td>
            <td data-label="Date/Time">{{ order.created_at.substring(0, 10) }}<br/>{{ order.created_at.substring(11, 19) }}</td>
            <td data-label="Deliver Charge">{{ order.deliver_charge }} KWD</td>
            <td data-label="Total Amount">{{ order.total_amount }} KWD</td>
            <td data-label="Status">{{ order.status }} KWD</td>
            <td data-label="Refund Reference">{{ order.refund_reference?order.refund_reference:'-' }}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="6">
              <v-icon icon="mdi-chevron-left" class="icon previous" />
              <span>{{ currPage }} - {{ totalPages }}</span>
              <v-icon icon="mdi-chevron-right" class="icon next" />
            </td>
          </tr>
        </tfoot>
      </table>      
    </div>
  </div>
</template>

<script>
// Loading
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

import store from '@/store'

export default {
  name: "CustomerOrdersView",
  data() {
    return {
      orders: [],
      currPage: 1,
      totalPages: 0,
      isLoading: false,
    }
  },
  methods: {
    getOrders() {
      this.isLoading = true;

      fetch(this.serverURL + 'api/users/' + localStorage.getItem('customer_id') +'/orders?page=' + this.currPage, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          this.isLoading = false;
          this.orders = data.data;
          
          // Meta
          if(data.meta.total > 0){
            this.currPage = data.meta.currentPage;
            this.totalPages = Math.ceil(data.meta.total / data.meta.perPage);
          }else{
            this.currPage = 0;
            this.totalPages = 0;
          }
        })
        .catch(error => {
          console.log(error);
        })
    },
  },
  beforeMount() {
    this.getOrders();
  },
  mounted(){
    let that = this;

    // On Previous Clicked
    window.$('.customer-orders .content table .previous').on('click', function() {
      if(that.currPage > 1){
        that.currPage -= 1;
        that.getOrders();
      }
    });

    // On Next Clicked
    window.$('.customer-orders .content table .next').on('click', function() {      
      if(that.currPage < that.totalPages){
        that.currPage += 1;
        that.getOrders();
      }
    });
  },
  components: {
    Loading
  },
};
</script>

<style>
.customer-orders{
  display: flex;
}

/* Temp  */
.customer-orders .temp{
  min-width: 250px;
  transition: all .5s;
}
.close-rail .customer-orders .temp{
  min-width: 70px;
  transition: all .5s;
}

/* Content */
.customer-orders .content{
  position: relative;
  width: -webkit-fill-available;
  background-color: #F1F1F1;
  min-height: 89vh;
  padding: 2rem;
  text-align: left;
}
@media(max-width: 576px){
  .customer-orders .content{
    padding: 1rem .5rem;
  }
}
.customer-orders .content .title{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}
.customer-orders .content .title h2{
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 0;
}
.customer-orders .content .title a{
  cursor: pointer;
  padding: .7rem 2rem;
  border-radius: 10px;
  font-size: 16px;
  font-weight: bold;
}

.customer-orders .content table{
  font-size: 14px;
}
</style>
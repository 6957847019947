<template>
  <div class="request-details" id="request-details">
    <div class="temp"></div>
    <div class="content">
      <div class="title">
        <h2 class="color-strong">Order Details</h2>
      </div>
      <div class="details">
        <div class="item">
          <div class="row">
            <div class="col-6">ID</div>
            <div class="col-6">{{ info.id }}</div>
          </div>
        </div>
        <div class="item">
          <div class="row">
            <div class="col-6">Date</div>
            <div class="col-6">{{ info.created_at ? info.created_at.substring(0, 10) : '' }}</div>
          </div>
        </div>
        <div class="item">
          <div class="row">
            <div class="col-6">Order id</div>
            <div class="col-6">{{ info.order_id }}</div>
          </div>
        </div>
        <div class="item">
          <div class="row">
            <div class="col-6">Type</div>
            <div class="col-6">{{ info.type }}</div>
          </div>
        </div>
        <div class="item">
          <div class="row">
            <div class="col-6">User Name</div>
            <div class="col-6">{{ info.user ? info.user.name : '' }}</div>
          </div>
        </div>
        <div class="item">
          <div class="row">
            <div class="col-6">User Email</div>
            <div class="col-6">{{ info.user ? info.user.email : '' }}</div>
          </div>
        </div>
        <div class="item">
          <div class="row">
            <div class="col-6">Mobile Number</div>
            <div class="col-6">{{ info.user ? info.user.phone : '' }}</div>
          </div>
        </div>
        <div class="item" v-if="info.products && info.products.length > 0">
          <div class="row">
            <div class="col-12">Products Details:</div>
            <div class="col-12">
              <table class="no-cards">
                <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Size</th>
                  <th scope="col">Color</th>
                  <th scope="col">Quantity</th>
                  <th scope="col">Price</th>
                </tr>
                </thead>
                <tbody>
                <tr class="record" v-for="product in info.products" :key="product.id">
                  <td data-label="Name">{{ product.name }}</td>
                  <td data-label="Color">
                    {{ product.product_color_size.color ? product.product_color_size.color.name : '-' }}
                  </td>
                  <td data-label="Size">{{
                      product.product_color_size.size ? product.product_color_size.size.name : '-'
                    }}
                  </td>
                  <td data-label="Quantity">{{ product.quantity }}</td>
                  <td data-label="Price">{{ product.price }} KWD</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import store from '@/store'

export default {
  name: "RequestDetailsView",
  props: {
    name: String
  },
  data() {
    return {
      info: {},
    }
  },
  methods: {
    getInfo() {
      this.isLoading = true;
      fetch(this.serverURL + 'api/exchange-requests/' + localStorage.getItem('request_id'), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
          .then(response => response.json())
          .then(data => {
            this.isLoading = false;
            this.info = data.data;
          })
          .catch(error => {
            console.log(error);
          })
    },
    beforeMount() {
      this.getInfo();
    },
  }
};
</script>

<style>
.request-details {
  display: flex;
}

/* Temp  */
.request-details .temp {
  min-width: 250px;
  transition: all .5s;
}

.close-rail .request-details .temp {
  min-width: 70px;
  transition: all .5s;
}

/* Content */
.request-details .content {
  position: relative;
  width: -webkit-fill-available;
  background-color: #F1F1F1;
  min-height: 89vh;
  padding: 2rem;
  text-align: left;
}

@media (max-width: 576px) {
  .request-details .content {
    padding: 1rem .5rem;
  }
}

.request-details .content .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}

.request-details .content .title h2 {
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 0;
}

.request-details .content .title i {
  font-size: 25px;
  padding: 1.2rem;
  background-color: #e9f3ef;
  border-radius: 10px;
  cursor: pointer;
}

/* Details */
.request-details .content .details .item {
  margin-top: 1rem;
}

.request-details .content .details .item .row div:first-of-type {
  color: black;
  font-weight: bold;
}

.request-details .content .details .item .row div:last-of-type {
  color: #404040;
}

.request-details .content .details .item table {
  margin-top: 1rem;
}


/* Init Table */
@media (max-width: 576px) {
  .request-details table {
    border: 0;
    box-shadow: none;
  }

  .request-details table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .request-details table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .625em;
    box-shadow: 0 1px 5px #CCC;
  }

  .request-details table tbody td {
    border-bottom: 1px solid #ddd;
    align-items: center;
    display: flex;
    justify-content: space-between;
    font-size: .8em;
    text-align: right;
  }

  .request-details table tfoot td {
    display: block;
  }

  .request-details table tbody td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  .request-details table tbody td:last-child {
    border-bottom: 0;
  }

  .request-details table tbody .result td:not(:last-of-type) {
    display: none;
  }
}

.request-details .content table {
  font-size: 14px;
}
</style>
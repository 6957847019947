<template>
  <div class="terms" id="terms">
    <div class="temp"></div>
    <div class="content">
      <Loading v-model:active="isLoading"
                 :can-cancel="true"
                 :is-full-page="false"
                 color="#B2CC9B"
                 loader="bars"
                 background-color="#000"
                 :lock-scroll="false"
                 />
      <div class="title">
        <h2 class="color-strong">Terms And Conditions Content</h2>
      </div>
      <form @submit.prevent="UpdateData">
        <div class="row">
          <div class="col-md-6">
            <div class="row">
              <div class="col-12">
                <label>Content*</label>
                <QuillEditor class="en_editor" theme="snow" v-model:content="termsData.en_content" toolbar="full" contentType="html" placeholder="Enter Content" />
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row ar">
              <div class="col-12">
                <label>المحتوى*</label>
                <QuillEditor class="ar_editor" theme="snow" v-model:content="termsData.ar_content" toolbar="full" contentType="html" placeholder="ادخل المحتوى" />
              </div>
            </div>
          </div>
        </div>
        <div class="error-message">
          <p v-for="error in errorMessages" :key="error">{{ error }}</p>
        </div>
        <input type="submit" class="submit-btn background" value="Save" :disabled="isLoading ? true:false" />
      </form>
    </div>
  </div>
</template>

<script>
// Loading
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

import store from '@/store'

// Quill Editor
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import '@vueup/vue-quill/dist/vue-quill.bubble.css';

export default {
  name: "TermsView",
  data() {
    return {
      termsData: {
        id: -1,
        en_content: null,
        ar_content: null,
      },
      errorMessages: [],
      isLoading: false,
    }
  },
  methods: {
    getInfo() {
      this.isLoading = true;

      fetch(this.serverURL + 'api/cms/pages/terms-and-conditions', {
        method: "GET",
        headers: {
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          this.isLoading = false;

          this.termsData.id = data.data.id;
          this.termsData.ar_content = data.data.translations[0].content;
          window.$('.terms .ar_editor .ql-editor').html(this.termsData.ar_content);
          this.termsData.en_content = data.data.content;
          window.$('.terms .en_editor .ql-editor').html(this.termsData.en_content);
        })
        .catch(error => {
          console.log(error);
        })
    },
    UpdateData() {
      this.isLoading = true;

      let formData = new FormData();
      if (this.termsData.en_content)
        formData.append("en[content]", this.termsData.en_content);
      if (this.termsData.ar_content)
        formData.append("ar[content]", this.termsData.ar_content);
      formData.append("_method", 'PUT');

      fetch(this.serverURL + 'api/pages/' + this.termsData.id,{
        method:  'POST',
        headers: {
          Authorization: `Bearer ${store.state.accessToken}`,
        },
        body: formData,
      })
        .then(response => response.json())
        .then(data => {
          this.isLoading = false;

          if (data.status == 200){
            location.reload();
          }else{
            let messages = [];

            for(let propt in data.errors){
              messages.push(data.errors[propt].join(' '));
            }
            this.errorMessages = messages;
          }
        })
        .catch(error => {
          console.error(error);
        })
    }
  },
  beforeMount() {
    this.getInfo();
  },
  mounted(){
  },
  components: {
    Loading,
    QuillEditor
  },
};
</script>

<style>
.terms{
  display: flex;
}

/* Temp  */
.terms .temp{
  min-width: 250px;
  transition: all .5s;
}
.close-rail .terms .temp{
  min-width: 70px;
  transition: all .5s;
}

/* Content */
.terms .content{
  position: relative;
  width: -webkit-fill-available;
  background-color: #F1F1F1;
  min-height: 89vh;
  padding: 2rem;
  text-align: left;
}
@media(max-width: 576px){
  .terms .content{
    padding: 1rem .5rem;
  }
}
.terms .content .title{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}
.terms .content .title h2{
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 0;
}

/* Quill Editor */
.terms .content .ql-toolbar{
  background-color: #F3F3F9;
  border-radius: 10px 10px 0 0;
}
.terms .content .ql-container{
  height: 10rem;
  background-color: #FFF;
  border-radius: 0 0 10px 10px;
}

/* Form */
.terms .content form .ar label{
  direction: rtl;
  text-align: right;
}
.terms .content form label {
  display: block;
  margin: 1rem 0 .5rem 0;
  color: black;
}
.terms .content form input {
  border: 1px solid #CCC;
  background-color: #FFF;
  padding: .5rem 1rem;
  border-radius: 10px;
  width: 90%;
}
.terms .content form .submit-btn {
  padding: 0.5rem 1rem;
  border-radius: 10px;
  color: #fff;
  box-shadow: 0 1px 5px #f1e7e7;
  font-weight: bold;
  display: block;
  margin-top: 2rem;
  width: 6rem;
}
</style>
<template>
  <div class="add-product" id="add-product">
    <!-- Modal -->
    <div
      class="modal fade"
      id="addProductModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="body">
            <div class="title">
              <h2 class="color-strong">Add Product</h2>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <form @submit.prevent="SendData">
              <div v-if="step == 1" class="row">
                <div class="col-md-6">
                  <label>Image</label>
                  <label for="image" class="upload-input"
                    >Image Upload
                    <img src="@/assets/icons/upload.png" alt="Upload Icon" />
                  </label>
                  <input
                    type="file"
                    id="image"
                    @change="handleImageChange"
                    accept="image/*"
                    style="display: none"
                  />
                </div>
                <div class="col-md-6"></div>
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-12">
                      <label>Title*</label>
                      <input type="text" v-model="ProductData.en_title" placeholder="Title" required/>
                    </div>
                    <div class="col-12">
                      <label>Description*</label>
                      <textarea v-model="ProductData.en_description" placeholder="Description" rows="5" required></textarea>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="row ar">
                    <div class="col-12">
                      <label>العنوان*</label>
                      <input type="text" v-model="ProductData.ar_title" placeholder="العنوان" required/>
                    </div>
                    <div class="col-12">
                      <label>الشرح*</label>
                      <textarea v-model="ProductData.ar_description" placeholder="الشرح" rows="5" required></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="step == 2" class="row">
                <div class="col-md-6">
                  <label>Category*</label>
                  <v-select
                    v-model="ProductData.category_id"
                    :items="categories"
                    item-title="name"
                    item-value="id"
                    variant="outlined"
                    required
                  ></v-select>
                </div>
                <div class="col-md-6">
                  <label>Subcategory*</label>
                  <v-select
                    v-model="ProductData.subcategory_id"
                    :items="subcategories"
                    item-title="name"
                    item-value="id"
                    variant="outlined"
                    :required="subcategories.length > 0"
                  ></v-select>
                </div>
                <div class="col-md-6">
                  <label>Price*</label>
                  <input type="text" v-model="ProductData.price" placeholder="Price" pattern="[0-9]+(.[0-9]+)?" required/>
                </div>
                <div class="col-md-6">
                  <label>Offer Price</label>
                  <input type="text" v-model="ProductData.offer_price" placeholder="Offer Price" pattern="[0-9]+(.[0-9]+)?" />
                </div>
                <div class="col-md-6">
                  <label>Start Date</label>
                  <input type="date" v-model="ProductData.start_date" placeholder="Start Date" :required="ProductData.offer_price != ''" />
                </div>
                <div class="col-md-6">
                  <label>End Date</label>
                  <input type="date" v-model="ProductData.end_date" placeholder="End Date" :required="ProductData.offer_price != ''" />
                </div>
                <div class="col-md-12">
                  <div class="row toggles">
                    <div class="col-sm-6 col-md-3">
                      <label>Best Selling</label>
                      <label class="switch">
                        <input type="checkbox" v-model="ProductData.best_selling" >
                        <span class="slider round"></span>
                      </label>
                    </div>
                    <div class="col-sm-6 col-md-3">
                      <label>New Arrival</label>
                      <label class="switch">
                        <input type="checkbox" v-model="ProductData.new_arrival" >
                        <span class="slider round"></span>
                      </label>
                    </div>
                    <div class="col-sm-6 col-md-3">
                      <label>Super Sale</label>
                      <label class="switch">
                        <input type="checkbox" v-model="ProductData.super_sale" >
                        <span class="slider round"></span>
                      </label>
                    </div>
                    <div class="col-sm-6 col-md-3">
                      <label>May Like</label>
                      <label class="switch">
                        <input type="checkbox" v-model="ProductData.featured" >
                        <span class="slider round"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="step == 3" class="row">
                <div class="col-md-6">
                  <label>Has Variants*</label>
                  <v-select
                    v-model="ProductData.variant"
                    :items="variants"
                    item-title="name"
                    item-value="value"
                    variant="outlined"
                    required
                  ></v-select>
                </div>
                <div class="col-md-6"></div>
                <div class="col-md-6">
                  <label>Color</label>
                  <v-select
                    class="color"
                    v-model="ProductData.color"
                    :items="colors"
                    item-title="name"
                    item-value="id"
                    return-object
                    variant="outlined"
                    clearable
                    chips
                    multiple
                    :disabled="variant == 'size'"
                  ></v-select>
                </div>
                <div class="col-md-6">
                  <label>Size</label>
                  <v-select
                    class="size"
                    v-model="ProductData.size"
                    :items="sizes"
                    item-title="name"
                    item-value="id"
                    return-object
                    variant="outlined"
                    clearable
                    chips
                    multiple
                    :disabled="variant == 'color'"
                  ></v-select>
                </div>
                <div class="col-md-12">
                  <input class="generate" type="button" value="Generate Combination" @click="generateTable">
                </div>
                <div class="col-md-12">
                  <table v-if="ProductData.combination.length != 0" class="combination-table no-cards">
                    <thead>
                      <tr>
                        <th scope="col">Color</th>
                        <th scope="col">Size</th>
                        <th scope="col">Stock</th>
                        <th scope="col">Images</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="el,index in ProductData.combination" :key="el" class="record">
                        <td data-label="Color">{{ el.color != null?el.color.name:'-'  }}</td>
                        <td data-label="Size">{{ el.size != null?el.size.name:'-'  }}</td>
                        <td data-label="Stock"><input type="text" v-model="el.stock" placeholder="Stock" pattern="[0-9]{1,}" required /></td>
                        <td data-label="Images">
                          <label :for="'images' + index" class="upload-input"
                            >Image Upload
                            <img src="@/assets/icons/upload.png" alt="Upload Icon" />
                          </label>
                          <input
                            type="file"
                            :id="'images' + index"
                            @change="(event) => { handleImages(event, el) }"
                            accept="image/*"
                            style="display: none"
                            multiple
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="error-message">
                <p v-for="error in errorMessages" :key="error">{{ error }}</p>
              </div>
              <div class="buttons">
                <input v-if="step != 1" type="button" value="Previous" class="prev" />
                <input v-if="step != 3" type="submit" value="Next" class="next" />      
                <input v-if="step == 3" type="submit" class="submit-btn background" value="Submit" :disabled="isLoading ? true:false" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store'

export default {
  name: "AddProductModal",
  data() {
    return {
      ProductData: {
        image: null,
        category_id: null,
        subcategory_id: null,
        en_title: '',
        ar_title: '',
        en_description: '',
        ar_description: '',
        price: '',
        offer_price: '',
        start_date: '',
        end_date: '',
        best_selling: false,
        new_arrival: false,
        super_sale: false,
        featured: false,
        variant: null,
        color: [],
        size: [],
        combination: []
      },
      categories: [],
      subcategories: [],
      variants: [
        {
          name: 'Color',
          value: 'color'
        },
        {
          name: 'Size',
          value: 'size'
        },
        {
          name: 'Both',
          value: 'both'
        }
      ],
      colors: [],
      sizes: [],
      step: 1,
      errorMessages: [],
      isLoading: false
    }
  },
  methods: {
    handleImageChange(e) {
      const selectedFile = e.target.files[0];
      this.ProductData.image = selectedFile;
    },
    handleImages: function (e, el) {
      const selectedFiles = e.target.files;
      el.images = selectedFiles;
    },
    generateTable(){
      this.errorMessages = [];
      this.ProductData.combination = [];

      if(this.ProductData.variant == null){
        this.errorMessages.push('Choose Variants First!');
      }else if(this.ProductData.variant == 'color' && this.ProductData.color.length == 0){
        this.errorMessages.push('Choose Colors First!');
      }else if(this.ProductData.variant == 'size' && this.ProductData.size.length == 0){
        this.errorMessages.push('Choose Sizes First!');
      }else if(this.ProductData.variant == 'both' && (this.ProductData.color.length == 0 || this.ProductData.size.length == 0)){
        this.errorMessages.push('Choose Colors,Sizes First!');
      }else{
        if(this.ProductData.variant == 'color'){
          for (let i = 0; i < this.ProductData.color.length; i++) {
            let obj = {
              color: this.ProductData.color[i],
              size: null,
              stock: 0,
              images: []
            };
            this.ProductData.combination.push(obj);
          }
        }else if(this.ProductData.variant == 'size'){
          for (let i = 0; i < this.ProductData.size.length; i++) {
            let obj = {
              color: null,
              size: this.ProductData.size[i],
              stock: 0,
              images: []
            };
            this.ProductData.combination.push(obj);
          }
        }else{
          for (let i = 0; i < this.ProductData.color.length; i++) {
            for (let j = 0; j < this.ProductData.size.length; j++) {
              let obj = {
                color: this.ProductData.color[i],
                size: this.ProductData.size[j],
                stock: 0,
                images: []
              };
              this.ProductData.combination.push(obj);
            }          
          }
        }
      }
    },
    getCategories() {
      fetch(this.serverURL + 'api/catalog/categories', {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          this.categories = data.data;
        })
        .catch(error => {
          console.log(error);
        })
    },
    getSubcategories() {
      fetch(this.serverURL + 'api/catalog/categories/' + this.ProductData.category_id + '/sub-categories', {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          this.subcategories = data.data;
        })
        .catch(error => {
          console.log(error);
        })
    },
    getSizes() {
      fetch(this.serverURL + 'api/catalog/sizes', {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          this.sizes = data.data;
        })
        .catch(error => {
          console.log(error);
        })
    },
    getColors() {
      fetch(this.serverURL + 'api/catalog/colors', {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          this.colors = data.data;
        })
        .catch(error => {
          console.log(error);
        })
    },
    SendData(){
      if (this.step == 3){
        this.isLoading = true;

        let formData = new FormData();
        if(this.ProductData.image)
          formData.append("image", this.ProductData.image);
        formData.append("category_id", this.ProductData.subcategory_id?this.ProductData.subcategory_id:this.ProductData.category_id);
        formData.append("price", this.ProductData.price);
        if(this.ProductData.offer_price){
          formData.append("offer_price", this.ProductData.offer_price);
          formData.append("offer_price_end", this.ProductData.end_date);
        }
        if(this.ProductData.best_selling)
          formData.append("best_selling", 1);
        if(this.ProductData.new_arrival)
          formData.append("new_arrival", 1);
        if(this.ProductData.super_sale)
          formData.append("super_sale", 1);
        if(this.ProductData.featured)
          formData.append("featured", 1);
        formData.append("en[name]", this.ProductData.en_title);
        formData.append("ar[name]", this.ProductData.ar_title);
        formData.append("en[description]", this.ProductData.en_description);
        formData.append("ar[description]", this.ProductData.ar_description);
        for(let i = 0; i < this.ProductData.combination.length; i++){
          if(this.ProductData.combination[i].color)
            formData.append("product_color_size[" + i + "][color_id]", this.ProductData.combination[i].color.id);
          if(this.ProductData.combination[i].size)
            formData.append("product_color_size[" + i + "][size_id]", this.ProductData.combination[i].size.id);
          formData.append("product_color_size[" + i + "][stock]", this.ProductData.combination[i].stock);
          for(let j = 0; j < this.ProductData.combination[i].images.length; j++){
            formData.append("product_color_size[" + i + "][images]["+ j +"]", this.ProductData.combination[i].images[j]);
          }
        }

        fetch(this.serverURL + 'api/catalog/products',{
          method:  'POST',
          headers: {
            Authorization: `Bearer ${store.state.accessToken}`,
          },
          body: formData,
        })
          .then(response => response.json())
          .then(data => {
            this.isLoading = false;

            if (data.status == 201){
              location.reload();
            }else{
              let messages = [];

              for(let propt in data.errors){
                messages.push(data.errors[propt].join(' '));
              }
              this.errorMessages = messages;
            }
          })
          .catch(error => {
            console.error(error);
          })
      }
      else{
        this.step += 1;
      }
    }
  },
  beforeMount() {
    this.getCategories();
    this.getColors();
    this.getSizes();
  },
  mounted(){
    let that = this;

    // On Prev Clicked
    window.$('body').on('click', '.add-product .modal form .buttons .prev',function() {
      that.step -= 1;
      console.log(that.step);
    });
  },
  computed: {
    variant() {
      return this.ProductData.variant;
    },
    category_id(){
      return this.ProductData.category_id;
    }
  },
  watch: {
    variant: function(){
      this.ProductData.size = [];
      this.ProductData.color = [];
      this.ProductData.combination = [];
    },
    category_id: function(){
      this.ProductData.subcategory_id = null;
      this.getSubcategories();
    }
  },
  components: {},
};
</script>

<style>
.add-product .modal{
  height: 100vh;
}
.add-product .modal .modal-content {
  border-radius: 1rem;
  box-shadow: 1px 1px 5px #856161;
  width: 35rem;
  max-height: 90vh;
  overflow: hidden;
}
.add-product .modal .body{
  height: 100%;
  overflow: auto;
  padding: 2rem;
}
@media(max-width: 767px){
  .add-product .modal .modal-content {
    width: initial;
  }
}
.add-product .modal .title{
  margin-bottom: 1rem !important;
}
.add-product .modal h2{
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 0;
}

/* Form */
.add-product .modal form .ar{
  direction: rtl;
  text-align: right;
}
.add-product .modal form .toggles > div{
  text-align: center;
}
.add-product .modal form label {
  display: block;
  margin: 1rem 0 .5rem 0;
  color: black;
}
.add-product .modal form input,
.add-product .modal form textarea,
.add-product .modal form .v-select,
.add-product .modal form label.upload-input {
  border: 1px solid #CCC;
  background-color: #FFF;
  padding: .5rem 1rem;
  border-radius: 10px;
  width: 90%;
}
.add-product .modal form label.upload-input {
  color: #6b6b6b;
  cursor: pointer;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.add-product .modal form label.upload-input img {
  width: 1.2rem;
  height: 1.1rem;
}
.add-product .modal form .v-select {
  outline: none;
  cursor: pointer;
}
.add-product .modal form .generate{
  width: fit-content;
  display: block;
  border: none;
  background-color: #6b6b6b;
  box-shadow: 0 1px 5px #CCC;
  margin: auto;
  margin-top: 1rem;
  font-weight: bold;
}
.add-product .modal form .generate:hover{
  background-color: #555151;
}
.add-product .modal form .combination-table{
  margin-top: 2rem;
}
.add-product .modal form .combination-table input,
.add-product .modal form .combination-table label.upload-input{
  width: 10rem;
}
.add-product .modal form .combination-table label.upload-input{
  background-color: #CCC;
  display: inline-flex;
  margin: 0;
}
.add-product .modal form .buttons{
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 2rem;
}
.add-product .modal form .buttons input {
  padding: 0.5rem 1rem;
  border-radius: 10px;
  color: #fff;
  box-shadow: 0 1px 5px #f1e7e7;
  font-weight: bold;
  display: block;
  width: 6rem;
}
.add-product .modal form .buttons .prev,
.add-product .modal form .buttons .next{
  background-color: #6C757D;
}

/* V-Select */
.add-product .modal form .v-select{
  padding: 0;
}
.add-product .modal form .v-select.v-input--disabled{
  background-color: #f3f3f3;
}
.add-product .modal form .v-select .v-field__input{
  padding: .5rem 1rem;
  min-height: 2.8rem;
}
.add-product .modal form .v-select .v-field{
  border-radius: 10px;
}
.add-product .modal form .v-select span,
.add-product .modal form .v-select .v-icon{
  color: black;
}
.add-product .modal form .v-select input{
  border: none;
  background-color: transparent;
  padding: 0;
  border-radius: 0;
  width: 100%;
}
.add-product .modal form .v-select label{
  margin: 0 1rem;
}
.add-product .modal form .v-select .v-input__details{
  display: none;
}

/* Init Table */
@media(max-width: 767px) {
  .add-product table {
    border: 0;
    box-shadow: none;
  }
  .add-product table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  .add-product table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .625em;
    box-shadow: 0 1px 5px #CCC;
  } 
  .add-product table tbody td {
    border-bottom: 1px solid #ddd;
    align-items: center;
    display: flex;
    justify-content: space-between;
    font-size: .8em;
    text-align: right;
  }
  .add-product table tfoot td{
    display: block;
  }
  .add-product table tbody td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }
  .add-product table tbody td:last-child {
    border-bottom: 0;
  }
}
</style>

<template>
  <div class="edit-size" id="edit-size">
    <!-- Modal -->
    <div
      class="modal fade"
      id="editSizeModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="title">
            <h2 class="color-strong">Edit Size</h2>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <form @submit.prevent="UpdateData">
            <div class="row">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-12">
                    <label>Title</label>
                    <input type="text" name="en[name]" :value="info.translations?info.translations[1].name:''" placeholder="Title" />
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="row ar">
                  <div class="col-12">
                    <label>العنوان</label>
                    <input type="text" name="ar[name]" :value="info.translations?info.translations[0].name:''" placeholder="العنوان" />
                  </div>
                </div>
              </div>
            </div>
            <div class="error-message">
              <p v-for="error in errorMessages" :key="error">{{ error }}</p>
            </div>            
            <input type="submit" class="submit-btn background" value="Submit" :disabled="isLoading ? true:false" />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store'

export default {
  name: "EditSizeModal",
  props: ['id'],
  data() {
    return {
      info: {},
      errorMessages: [],
      isLoading: false
    }
  },
  methods: {
    getInfo() {
      fetch(this.serverURL + 'api/catalog/sizes/' + this.id, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          this.info = data.data;
        })
        .catch(error => {
          console.log(error);
        })
    },
    sendData(data) {
      fetch(this.serverURL + 'api/catalog/sizes/' + this.id,{
        method: "POST",
        headers: {
          Authorization: `Bearer ${store.state.accessToken}`,
        },
        body: data,
      })
        .then(response => response.json())
        .then(data => {
          if (data.status == 200){
            location.reload();
          }else{
            let messages = [];

            for(let propt in data.errors){
              messages.push(data.errors[propt].join(' '));
            }
            this.errorMessages = messages;
          }
        })
        .catch(error => {
          console.log(error);
        })
    },
    UpdateData(){
      let inputs = window.$('.edit-size :input[name]');
      let formData = new FormData();
      
      inputs.each(function() {
        if (this.value != "")
          formData.append(this.name, this.value);
      });
      formData.append('_method', 'PUT');
      // console.log(Object.fromEntries(formData.entries()));

      this.sendData(formData);
    }
  },
  watch: {
    id: function(){
      this.getInfo();
    }
  },
  components: {},
};
</script>

<style>
.edit-size .modal .modal-content {
  border-radius: 1rem;
  box-shadow: 1px 1px 5px #856161;
  padding: 2rem;
}
.edit-size .modal .title{
  margin-bottom: 1rem !important;
}
.edit-size .modal h2{
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 0;
}

/* Form */
.edit-size .modal form .ar{
  direction: rtl;
  text-align: right;
}
.edit-size .modal form label {
  display: block;
  margin: 1rem 0 .5rem 0;
  color: black;
}
.edit-size .modal form input {
  border: 1px solid #CCC;
  background-color: #FFF;
  padding: .5rem 1rem;
  border-radius: 10px;
  width: 90%;
}
.edit-size .modal form .submit-btn {
  padding: 0.5rem 1rem;
  border-radius: 10px;
  color: #fff;
  box-shadow: 0 1px 5px #f1e7e7;
  font-weight: bold;
  display: block;
  margin-top: 2rem;
  width: 6rem;
}
</style>

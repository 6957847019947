<template>
  <div class="add-size" id="add-size">
    <!-- Modal -->
    <div
      class="modal fade"
      id="addSizeModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="title">
            <h2 class="color-strong">Add Size</h2>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <form @submit.prevent="SendData">
            <div class="row">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-12">
                    <label>Title*</label>
                    <input type="text" v-model="sizeData.en_title" placeholder="Title" required/>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="row ar">
                  <div class="col-12">
                    <label>العنوان*</label>
                    <input type="text" v-model="sizeData.ar_title" placeholder="العنوان" required/>
                  </div>
                </div>
              </div>
            </div>
            <div class="error-message">
              <p v-for="error in errorMessages" :key="error">{{ error }}</p>
            </div>            
            <input type="submit" class="submit-btn background" value="Submit" :disabled="isLoading ? true:false" />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store'

export default {
  name: "AddSizeModal",
  data() {
    return {
      sizeData: {
        en_title: '',
        ar_title: '',
      },
      errorMessages: [],
      isLoading: false
    }
  },
  methods: {
    SendData() {
      this.isLoading = true;

      let formData = new FormData();
      formData.append("en[name]", this.sizeData.en_title);
      formData.append("ar[name]", this.sizeData.ar_title);

      fetch(this.serverURL + 'api/catalog/sizes',{
        method:  'POST',
        headers: {
          Authorization: `Bearer ${store.state.accessToken}`,
        },
        body: formData,
      })
        .then(response => response.json())
        .then(data => {
          this.isLoading = false;
          if (data.status == 201){
            location.reload();
          }else{
            let messages = [];

            for(let propt in data.errors){
              messages.push(data.errors[propt].join(' '));
            }
            this.errorMessages = messages;
          }
        })
        .catch(error => {
          console.error(error);
        })
    }
  },
  components: {},
};
</script>

<style>
.add-size .modal .modal-content {
  border-radius: 1rem;
  box-shadow: 1px 1px 5px #856161;
  padding: 2rem;
}
.add-size .modal .title{
  margin-bottom: 1rem !important;
}
.add-size .modal h2{
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 0;
}

/* Form */
.add-size .modal form .ar{
  direction: rtl;
  text-align: right;
}
.add-size .modal form label {
  display: block;
  margin: 1rem 0 .5rem 0;
  color: black;
}
.add-size .modal form input {
  border: 1px solid #CCC;
  background-color: #FFF;
  padding: .5rem 1rem;
  border-radius: 10px;
  width: 90%;
}
.add-size .modal form .submit-btn {
  padding: 0.5rem 1rem;
  border-radius: 10px;
  color: #fff;
  box-shadow: 0 1px 5px #f1e7e7;
  font-weight: bold;
  display: block;
  margin-top: 2rem;
  width: 6rem;
}
</style>

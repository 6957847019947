<template>
  <div class="navbarr" id="navbarr">
    <div class="temp"></div>
    <div class="content">
      <div class="top-bar">
        <div class="mode" @click="toggleMode">
          <v-icon icon="mdi-theme-light-dark" />
        </div>
        <div class="notification">
          <v-icon icon="mdi-bell-outline" />
        </div>
        <div class="profile">
          <img src="@/assets/images/navbar/person.jpg" alt="Person Image">
          <div class="name">
            <span class="color-strong">{{ info.name }}</span>
            <!-- <span>{{ info.role }}</span> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store'

export default {
  name: "AppNavbar",
  data() {
    return {
    }
  },
  methods: {
    toggleMode(){
      if(this.mode == 'light'){
        store.state.appMode = 'dark';
      }else{
        store.state.appMode = 'light';
      }
    }
  },
  mounted() {
    //  Sticky Menu
    var $window = window.$(window);
    $window.on("scroll", function () {
      if ($window.scrollTop() > 20) {
        window.$(".navbarr").addClass("sticky");
      } else {
        window.$(".navbarr").removeClass("sticky");
      }
    });
  },
  components: {},
  computed: {
    info () {
      return store.state.adminInfo;
    },
    lang () {
      return store.state.appLang;
    },
    mode () {
      return store.state.appMode;
    }
  },
};
</script>

<style>
.navbarr{
  display: flex;
}
.navbarr.sticky{
  position: sticky;
  width: 100%;
  top: 0;
  margin-top: 0;
  z-index: 99;
}

/* Temp  */
.navbarr .temp{
  min-width: 250px;
  transition: all .5s;
}
.close-rail .navbarr .temp{
  min-width: 70px;
  transition: all .5s;
}

/* Content */
.navbarr .content{
  width: -webkit-fill-available;
}
.navbarr .content .top-bar{
  background-color: #FFF;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 1.5rem;
  height: 11vh;
  box-shadow: 0 1px 5px #CCC !important;
}
@media(max-width: 576px){
  .navbarr .content .top-bar{
    padding: 0 .5rem;
  }
}
.navbarr .content .top-bar .mode,
.navbarr .content .top-bar .notification{
  margin-right: 1rem;
  padding: .5rem;
  transition: all .5s;
  cursor: pointer;
  border-radius: 50%;
}
.navbarr .content .top-bar .mode:hover,
.navbarr .content .top-bar .notification:hover{
  background-color: #F3F3F9;
}
.navbarr .content .top-bar i{
  color: #878A99;
}
.navbarr .content .top-bar .profile{
  border-radius: 5px;
  background-color: #F3F3F9;
  color: #7FB4F9;
  height: 100%;
  display: flex;
  align-items: center;
  text-align: left;
  cursor: pointer;
  padding: 1rem;
}
.navbarr .content .top-bar .profile img{
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  margin-right: .5rem;
}
@media(max-width: 767px){
  .navbarr .content .top-bar .profile img{
    margin-right: 0;
  }
  .navbarr .content .top-bar .profile .name{
    display: none;
  }
}
.navbarr .content .top-bar .profile .name span:last-of-type{
  color: #878A99;
  display: block;
  font-size: .7rem;
}
</style>
<template>
  <div class="edit-admin" id="edit-admin">
    <!-- Modal -->
    <div
      class="modal fade"
      id="editAdminModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="title">
            <h2 class="color-strong">Edit Admin</h2>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <form @submit.prevent="UpdateData">
            <div class="row">
              <div class="col-6">
                <label>Full Name</label>
                <input type="text" v-model="info.name" placeholder="Full Name" />
              </div>
              <div class="col-6">
                <label>Email</label>
                <input type="email" v-model="info.email" placeholder="Email" />
              </div>
              <div class="col-12">
                <label>Mobile Number</label>
                <input type="text" pattern="[0-9]{1,}" v-model="info.phone" placeholder="Mobile Number" />
              </div>
              <div class="col-12">
                <label>Password</label>
                <input type="password" v-model="info.password" placeholder="Password" />
              </div>
              <div class="col-12">
                  <label>Role</label>
                  <v-select
                    class="permission"
                    v-model="info.role"
                    :items="roles"
                    item-title="name"
                    item-value="id"
                    variant="outlined"
                    clearable
                    chips
                    multiple
                  ></v-select>
                </div>
            </div>
            <div class="error-message">
              <p v-for="error in errorMessages" :key="error">{{ error }}</p>
            </div>            
            <input type="submit" class="submit-btn background" value="Submit" :disabled="isLoading ? true:false" />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store'

export default {
  name: "EditSizeModal",
  props: ['id'],
  data() {
    return {
      info: {
        name: '',
        email: '',
        phone: '',
        password: '',
        role: []
      },
      roles: [],
      errorMessages: [],
      isLoading: false
    }
  },
  methods: {
    getRoles() {
      this.isLoading = true;

      fetch(this.serverURL + 'api/roles?page=' + this.currPage, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          this.isLoading = false;
          this.roles = data.data;
        })
        .catch(error => {
          console.log(error);
        })
    },
    getInfo() {
      this.isLoading = true;

      fetch(this.serverURL + 'api/admins/' + this.id, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          this.isLoading = false;
          this.info = {
            name: '',
            email: '',
            phone: '',
            password: '',
            role: []
          };
          this.info.name = data.data.name;
          this.info.email = data.data.email;
          this.info.phone = data.data.phone;
          this.info.password = data.data.password;
          for(let i = 0; i < data.data.roles.length; i++){
            this.info.role.push(data.data.roles[i].id);
          }
        })
        .catch(error => {
          console.log(error);
        })
    },
    UpdateData() {
      this.isLoading = true;

      let formData = new FormData();
      if(this.info.name)
        formData.append("name", this.info.name);
      if(this.info.email)
        formData.append("email", this.info.email);
      if(this.info.phone)
        formData.append("phone", this.info.phone);
      if(this.info.password)
        formData.append("password", this.info.password);
      if(this.info.role.length > 0)
        for(let i = 0; i < this.info.role.length; i++){
          formData.append("roles[" + i + "]", this.info.role[i]);
        }
      formData.append("_method", "PUT");

      fetch(this.serverURL + 'api/admins/' + this.id,{
        method:  'POST',
        headers: {
          Authorization: `Bearer ${store.state.accessToken}`,
        },
        body: formData,
      })
        .then(response => response.json())
        .then(data => {
          this.isLoading = false;

          if (data.status == 200){
            location.reload();
          }else{
            let messages = [];

            for(let propt in data.errors){
              messages.push(data.errors[propt].join(' '));
            }
            this.errorMessages = messages;
          }
        })
        .catch(error => {
          console.error(error);
        })
    },
  },
  beforeMount() {
    this.getRoles();
  },
  watch: {
    id: function(){
      this.getInfo();
    }
  },
  components: {},
};
</script>

<style>
.edit-admin .modal .modal-content {
  border-radius: 1rem;
  box-shadow: 1px 1px 5px #856161;
  padding: 2rem;
}
.edit-admin .modal .title{
  margin-bottom: 1rem !important;
}
.edit-admin .modal h2{
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 0;
}

/* Form */
.edit-admin .modal form .ar{
  direction: rtl;
  text-align: right;
}
.edit-admin .modal form label {
  display: block;
  margin: 1rem 0 .5rem 0;
  color: black;
}
.edit-admin .modal form input,
.edit-admin .modal form .v-select {
  border: 1px solid #CCC;
  background-color: #FFF;
  padding: .5rem 1rem;
  border-radius: 10px;
  width: 90%;
}
.edit-admin .modal form .submit-btn {
  padding: 0.5rem 1rem;
  border-radius: 10px;
  color: #fff;
  box-shadow: 0 1px 5px #f1e7e7;
  font-weight: bold;
  display: block;
  margin-top: 2rem;
  width: 6rem;
}

/* V-Select */
.edit-admin .modal form .v-select{
  padding: 0;
}
.edit-admin .modal form .v-select.v-input--disabled{
  background-color: #f3f3f3;
}
.edit-admin .modal form .v-select .v-field__input{
  padding: .5rem 1rem;
  min-height: 2.8rem;
}
.edit-admin .modal form .v-select .v-field{
  border-radius: 10px;
}
.edit-admin .modal form .v-select span,
.edit-admin .modal form .v-select .v-icon{
  color: black;
}
.edit-admin .modal form .v-select input{
  border: none;
  background-color: transparent;
  padding: 0;
  border-radius: 0;
  width: 100%;
}
.edit-admin .modal form .v-select label{
  margin: 0 1rem;
}
.edit-admin .modal form .v-select .v-input__details{
  display: none;
}
</style>

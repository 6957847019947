<template>
  <div class="product" id="product">
    <div class="temp"></div>
    <div class="content">
      <Loading v-model:active="isLoading"
                 :can-cancel="true"
                 :is-full-page="false"
                 color="#B2CC9B"
                 loader="bars"
                 background-color="#000"
                 :lock-scroll="false"
                 />
      <div class="title">
        <h2 class="color-strong">Product Management</h2>
        <a class="background" data-bs-toggle="modal" data-bs-target="#addProductModal">Add</a>
      </div>
      <table>
        <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Image</th>
            <th scope="col">Name</th>
            <th scope="col">Category</th>
            <th scope="col">Subcategory</th>
            <th scope="col">Remaining Quantity</th>
            <th scope="col">Sold Quantity</th>
            <th scope="col">Price</th>
            <th scope="col">Offer Price</th>
            <th scope="col">Status</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr class="record" v-for="product in products" :key="product.id" :data-id="product.id" :data-name="product.name">
            <td data-label="ID">{{ product.id }}</td>
            <td data-label="Image"><img :src="product.image" alt="Product Image"></td>
            <td data-label="Name">{{ product.name }}</td>
            <td data-label="Category">{{ product.category?product.category.parent?product.category.parent.name:product.category.name:'' }}</td>
            <td data-label="Subcategory">{{ product.category?product.category.parent?product.category.name:'-':'' }}</td>
            <td data-label="Remaining Quantity">{{ product.remaining_quantity }}</td>
            <td data-label="Sold Quantity">{{ product.sold_quantity }}</td>
            <td data-label="Price">{{ product.price }} KWD</td>
            <td data-label="Offer Price">{{ product.offer_price?product.offer_price + ' KWD':'-' }}</td>
            <td data-label="Status">
              <div class="status">
                <label class="switch">
                  <input type="checkbox" :checked="product.status == 'ACTIVE'" />
                  <span class="slider round"></span>
                </label>
              </div>
            </td>
            <td data-label="Actions">
              <div class="actions">
                <v-icon icon="mdi-eye-outline" class="view" />
                <v-icon icon="mdi-notebook-edit-outline" class="edit"
                  data-bs-toggle="modal" data-bs-target="#editProductModal" />
                <v-icon icon="mdi-delete-empty-outline" class="delete"
                  data-bs-toggle="modal" data-bs-target="#deletModal" />
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="11">
              <v-icon icon="mdi-chevron-left" class="icon previous" />
              <span>{{ currPage }} - {{ totalPages }}</span>
              <v-icon icon="mdi-chevron-right" class="icon next" />
            </td>
          </tr>
        </tfoot>
      </table>

      <add-product-modal />
      <edit-product-modal :id="updateId" @closeModal="closeModal"/>
      <app-delete-modal modalTitle="Delete Product"/>
    </div>
  </div>
</template>

<script>
// Loading
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

import store from '@/store'

import AppDeleteModal from '@/components/global/AppDeleteModal.vue'
import AddProductModal from '@/views/Product/AddProductModal.vue'
import EditProductModal from '@/views/Product/EditProductModal.vue'

export default {
  name: "ProductView",
  data() {
    return {
      products: [],
      currPage: 1,
      totalPages: 0,
      deleteId: -1,
      updateId: -1,
      isLoading: false,
    }
  },
  methods: {
    closeModal(){
      this.updateId = -1;
    },
    getProducts() {
      this.isLoading = true;

      fetch(this.serverURL + 'api/catalog/products?page=' + this.currPage, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          this.isLoading = false;
          this.products = data.data;
          
          // Meta
          if(data.meta.total > 0){
            this.currPage = data.meta.currentPage;
            this.totalPages = Math.ceil(data.meta.total / data.meta.perPage);
          }else{
            this.currPage = 0;
            this.totalPages = 0;
          }
        })
        .catch(error => {
          console.log(error);
        })
    },
    deleteProduct() {
      fetch(this.serverURL + 'api/catalog/products/' + this.deleteId,{
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
        .then(response => response.json())
        .then(function(){
          location.reload();
        })
        .catch()
    },
    activeProduct(id) {
      fetch(this.serverURL + 'api/catalog/products/' + id + '/activate',{
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
        .then(response => response.json())
        .then()
        .catch()
    },
    deactiveProduct(id) {
      fetch(this.serverURL + 'api/catalog/products/' + id + '/deactivate',{
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
        .then(response => response.json())
        .then()
        .catch()
    }
  },
  beforeMount() {
    this.getProducts();
  },
  mounted(){
    let that = this;

    // On Checkbox change
    window.$('body').on('change', '.product .content table .status .switch input[type="checkbox"]', function() {
      if (this.checked){
        that.activeProduct(window.$(this).parents('.record').data('id'));
      }else{
        that.deactiveProduct(window.$(this).parents('.record').data('id'));
      }
    });

    // On View Clicked
    window.$('body').on('click', '.product .content table .actions .view', function() {
      localStorage.setItem('product_id', window.$(this).parents('.record').data('id'));

      let fullPath = '/ProductDetails/' + window.$(this).parents('.record').data('name').replaceAll(' ', '-');
      that.$router.push({ path: fullPath });
    });

    // On Delete Clicked
    window.$('body').on('click', '.product .content table .actions .delete', function() {
      that.deleteId = window.$(this).parents('.record').data('id');
    });

    // On Update Clicked
    window.$('body').on('click', '.product .content table .actions .edit', function() {
      that.updateId = window.$(this).parents('.record').data('id');
    });

    // On Yes Clicked
    window.$('.product .content .yes-btn').on('click', function() {
      that.deleteProduct();
    });

    // On Previous Clicked
    window.$('.product .content table .previous').on('click', function() {
      if(that.currPage > 1){
        that.currPage -= 1;
        that.getProducts();
      }
    });

    // On Next Clicked
    window.$('.product .content table .next').on('click', function() {
      if(that.currPage < that.totalPages){
        that.currPage += 1;
        that.getProducts();
      }
    });
  },
  components: {
    AppDeleteModal,
    AddProductModal,
    EditProductModal,
    Loading
  },
};
</script>

<style>
.product{
  display: flex;
}

/* Temp  */
.product .temp{
  min-width: 250px;
  transition: all .5s;
}
.close-rail .product .temp{
  min-width: 70px;
  transition: all .5s;
}

/* Content */
.product .content{
  position: relative;
  width: -webkit-fill-available;
  background-color: #F1F1F1;
  min-height: 89vh;
  padding: 2rem;
  text-align: left;
}
@media(max-width: 576px){
  .product .content{
    padding: 1rem .5rem;
  }
}
.product .content .title{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}
.product .content .title h2{
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 0;
}
.product .content .title a{
  text-decoration: none;
  color: #FFF;
  cursor: pointer;
  padding: .7rem 2rem;
  border-radius: 10px;
  font-size: 16px;
  font-weight: bold;
  box-shadow: 0 1px 5px #CCC;
}

/* Table */
.product .content table img{
  width: 5rem;
}
.product .content table .actions{
  display: flex;
  align-items: center;
  justify-content: center;
}
.product .content table .actions i{
  font-size: 20px;
  padding: 1rem;
  background-color: #e9f3ef;
  border-radius: 10px;
  cursor: pointer;
}
.product .content table .actions i:nth-of-type(2){
  margin: 0 .5rem;
}
.product .content table .actions i:hover{
  color: #6F825F;
}

/* Init Table */
@media(max-width: 1300px) {
  .product table:not(.no-cards) {
    border: 0;
    box-shadow: none;
  }
  .product table:not(.no-cards) thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  .product table:not(.no-cards) tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .625em;
    box-shadow: 0 1px 5px #CCC;
  } 
  .product table:not(.no-cards) tbody td {
    border-bottom: 1px solid #ddd;
    align-items: center;
    display: flex;
    justify-content: space-between;
    font-size: .8em;
    text-align: right;
  }
  .product table:not(.no-cards) tfoot td{
    display: block;
  }
  .product table:not(.no-cards) tbody td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }
  .product table:not(.no-cards) tbody td:last-child {
    border-bottom: 0;
  }
}

.product .content table{
  font-size: 14px;
}
</style>
<template>
  <div class="add-admin" id="add-admin">
    <!-- Modal -->
    <div
      class="modal fade"
      id="addAdminModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="title">
            <h2 class="color-strong">Add Admin</h2>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <form @submit.prevent="SendData">
            <div class="row">
              <div class="col-6">
                <label>Full Name*</label>
                <input type="text" v-model="adminData.name" placeholder="Full Name" required/>
              </div>
              <div class="col-6">
                <label>Email*</label>
                <input type="email" v-model="adminData.email" placeholder="Email" required/>
              </div>
              <div class="col-12">
                <label>Mobile Number*</label>
                <input type="text" pattern="[0-9]{1,}" v-model="adminData.phone" placeholder="Mobile Number" required/>
              </div>
              <div class="col-12">
                <label>Password*</label>
                <input type="password" v-model="adminData.password" placeholder="Password" required/>
              </div>
              <div class="col-12">
                  <label>Role*</label>
                  <v-select
                    class="permission"
                    v-model="adminData.role"
                    :items="roles"
                    item-title="name"
                    item-value="id"
                    variant="outlined"
                    clearable
                    chips
                    multiple
                    required
                  ></v-select>
                </div>
            </div>
            <div class="error-message">
              <p v-for="error in errorMessages" :key="error">{{ error }}</p>
            </div>            
            <input type="submit" class="submit-btn background" value="Submit" :disabled="isLoading ? true:false" />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store'

export default {
  name: "AddAdminModal",
  data() {
    return {
      adminData: {
        name: '',
        email: '',
        phone: '',
        password: '',
        role: []
      },
      roles: [],
      errorMessages: [],
      isLoading: false
    }
  },
  methods: {
    getRoles() {
      this.isLoading = true;

      fetch(this.serverURL + 'api/roles?page=' + this.currPage, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          this.isLoading = false;
          this.roles = data.data;
        })
        .catch(error => {
          console.log(error);
        })
    },
    SendData() {
      this.isLoading = true;

      let formData = new FormData();
      formData.append("name", this.adminData.name);
      formData.append("email", this.adminData.email);
      formData.append("phone", this.adminData.phone);
      formData.append("password", this.adminData.password);
      for(let i = 0; i < this.adminData.role.length; i++){
        formData.append("roles[" + i + "]", this.adminData.role[i]);
      }

      fetch(this.serverURL + 'api/admins',{
        method:  'POST',
        headers: {
          Authorization: `Bearer ${store.state.accessToken}`,
        },
        body: formData,
      })
        .then(response => response.json())
        .then(data => {
          this.isLoading = false;

          if (data.status == 201){
            location.reload();
          }else{
            let messages = [];

            for(let propt in data.errors){
              messages.push(data.errors[propt].join(' '));
            }
            this.errorMessages = messages;
          }
        })
        .catch(error => {
          console.error(error);
        })
    }
  },
  beforeMount() {
    this.getRoles();
  },
  components: {},
};
</script>

<style>
.add-admin .modal .modal-content {
  border-radius: 1rem;
  box-shadow: 1px 1px 5px #856161;
  padding: 2rem;
}
.add-admin .modal .title{
  margin-bottom: 1rem !important;
}
.add-admin .modal h2{
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 0;
}

/* Form */
.add-admin .modal form label {
  display: block;
  margin: 1rem 0 .5rem 0;
  color: black;
}
.add-admin .modal form input,
.add-admin .modal form .v-select {
  border: 1px solid #CCC;
  background-color: #FFF;
  padding: .5rem 1rem;
  border-radius: 10px;
  width: 90%;
}
.add-admin .modal form .v-select {
  outline: none;
  cursor: pointer;
}
.add-admin .modal form .submit-btn {
  padding: 0.5rem 1rem;
  border-radius: 10px;
  color: #fff;
  box-shadow: 0 1px 5px #f1e7e7;
  font-weight: bold;
  display: block;
  margin-top: 2rem;
  width: 6rem;
}

/* V-Select */
.add-admin .modal form .v-select{
  padding: 0;
}
.add-admin .modal form .v-select.v-input--disabled{
  background-color: #f3f3f3;
}
.add-admin .modal form .v-select .v-field__input{
  padding: .5rem 1rem;
  min-height: 2.8rem;
}
.add-admin .modal form .v-select .v-field{
  border-radius: 10px;
}
.add-admin .modal form .v-select span,
.add-admin .modal form .v-select .v-icon{
  color: black;
}
.add-admin .modal form .v-select input{
  border: none;
  background-color: transparent;
  padding: 0;
  border-radius: 0;
  width: 100%;
}
.add-admin .modal form .v-select label{
  margin: 0 1rem;
}
.add-admin .modal form .v-select .v-input__details{
  display: none;
}
</style>

import { createRouter, createWebHistory } from 'vue-router'
import LoginView from "@/views/Auth/LoginView.vue";
import Dashboard from '@/views/Dashboard/DashboardView.vue'
import Category from '@/views/Category/CategoryView.vue'
import CategoryProducts from '@/views/Category/CategoryProductsView.vue'
import Subcategory from '@/views/Subcategory/SubcategoryView.vue'
import SubcategoryProducts from '@/views/Subcategory/SubcategoryProductsView.vue'
import Color from '@/views/Color/ColorView.vue'
import Size from '@/views/Size/SizeView.vue'
import Product from '@/views/Product/ProductView.vue'
import ProductDetails from '@/views/Product/ProductDetailsView.vue'
import Order from '@/views/Order/OrderView.vue'
import OrderDetails from '@/views/Order/OrderDetailsView.vue'
import Role from '@/views/Role/RoleView.vue'
import Admin from '@/views/Admin/AdminView.vue'
import Customer from '@/views/Customer/CustomerView.vue'
import CustomerOrders from '@/views/Customer/CustomerOrdersView.vue'
import CustomerAddresses from '@/views/Customer/CustomerAddressesView.vue'
import AboutUs from '@/views/Content/AboutUsView.vue'
import PrivacyPolicy from '@/views/Content/PrivacyPolicyView.vue'
import ReturnPolicy from '@/views/Content/ReturnPolicyView.vue'
import Terms from '@/views/Content/TermsView.vue'
import SocialMedia from '@/views/Content/SocialMediaView.vue'
import SliderBanner from '@/views/Content/SliderBanner/SliderBannerView.vue'
import PromoCode from '@/views/PromoCode/PromoCodeView.vue'
import ContactUs from '@/views/ContactUs/ContactUsView.vue'
import RefundRequests from '@/views/Refund Requests/RefundAndExchangeRequests.vue'
import RefundDetails from '@/views/Refund Requests/RefundAndExchangeRequestDetails.vue'

const routes = [
  {
    path: "/",
    name: "Login",
    component: LoginView,
  },
  {
    path: '/Dashboard',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/Category',
    name: 'Category',
    component: Category
  },
  {
    path: '/Subcategory',
    name: 'Subcategory',
    component: Subcategory
  },
  {
    path: '/CategoryProducts/:name',
    name: 'CategoryProducts',
    component: CategoryProducts,
    props: true
  },
  {
    path: '/SubcategoryProducts/:name',
    name: 'SubcategoryProducts',
    component: SubcategoryProducts,
    props: true
  },
  {
    path: '/Color',
    name: 'Color',
    component: Color,
  },
  {
    path: '/Size',
    name: 'Size',
    component: Size,
  },
  {
    path: '/Product',
    name: 'Product',
    component: Product,
  },
  {
    path: '/ProductDetails/:name',
    name: 'ProductDetails',
    component: ProductDetails,
    props: true
  },
  {
    path: '/Order',
    name: 'Order',
    component: Order,
  },
  {
    path: '/OrderDetails/:name',
    name: 'OrderDetails',
    component: OrderDetails,
    props: true
  },
  {
    path: '/Role',
    name: 'Role',
    component: Role,
  },
  {
    path: '/Admin',
    name: 'Admin',
    component: Admin,
  },
  {
    path: '/Customer',
    name: 'Customer',
    component: Customer,
  },
  {
    path: '/CustomerOrders/:name',
    name: 'CustomerOrders',
    component: CustomerOrders,
    props: true
  },
  {
    path: '/CustomerAddresses/:name',
    name: 'CustomerAddresses',
    component: CustomerAddresses,
    props: true
  },
  {
    path: '/AboutUs',
    name: 'AboutUs',
    component: AboutUs,
  },
  {
    path: '/PrivacyPolicy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
  },
  {
    path: '/ReturnPolicy',
    name: 'ReturnPolicy',
    component: ReturnPolicy,
  },
  {
    path: '/Terms',
    name: 'Terms',
    component: Terms,
  },
  {
    path: '/SocialMedia',
    name: 'SocialMedia',
    component: SocialMedia,
  },
  {
    path: '/SliderBanner',
    name: 'SliderBanner',
    component: SliderBanner,
  },
  {
    path: '/PromoCode',
    name: 'PromoCode',
    component: PromoCode,
  },
  {
    path: '/ContactUs',
    name: 'ContactUs',
    component: ContactUs,
  },
  {
    path: '/RefundRequests',
    name: 'RefundRequests',
    component: RefundRequests,
  },

  {
    path: '/RefundDetails/',
    name: 'RefundDetails',
    component: RefundDetails,
    props: true
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

<template>
  <div class="add-role" id="add-role">
    <!-- Modal -->
    <div
      class="modal fade"
      id="addRoleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="title">
            <h2 class="color-strong">Add Role</h2>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <form @submit.prevent="SendData">
            <div class="row">
              <div class="col-12">
                <label>Name*</label>
                <input type="text" v-model="roleData.name" placeholder="Name" required/>
              </div>
              <div class="col-12">
                  <label>Permission*</label>
                  <v-select
                    class="permission"
                    v-model="roleData.permission"
                    :items="permissions"
                    item-title="name"
                    item-value="id"
                    variant="outlined"
                    clearable
                    chips
                    multiple
                    required
                  ></v-select>
                </div>
            </div>
            <div class="error-message">
              <p v-for="error in errorMessages" :key="error">{{ error }}</p>
            </div>            
            <input type="submit" class="submit-btn background" value="Submit" :disabled="isLoading ? true:false" />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store'

export default {
  name: "AddRoleModal",
  data() {
    return {
      roleData: {
        name: '',
        permission: []
      },
      permissions: [],
      errorMessages: [],
      isLoading: false
    }
  },
  methods: {
    getPermissions() {
      this.isLoading = true;

      fetch(this.serverURL + 'api/permissions', {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          this.isLoading = false;
          this.permissions = data.data;
        })
        .catch(error => {
          console.log(error);
        })
    },
    SendData() {
      this.isLoading = true;

      let formData = new FormData();
      formData.append("name", this.roleData.name);
      for(let i = 0; i < this.roleData.permission.length; i++){
        formData.append("permissions[" + i + "]", this.roleData.permission[i]);
      }

      fetch(this.serverURL + 'api/roles',{
        method:  'POST',
        headers: {
          Authorization: `Bearer ${store.state.accessToken}`,
        },
        body: formData,
      })
        .then(response => response.json())
        .then(data => {
          this.isLoading = false;

          if (data.status == 201){
            location.reload();
          }else{
            let messages = [];

            for(let propt in data.errors){
              messages.push(data.errors[propt].join(' '));
            }
            this.errorMessages = messages;
          }
        })
        .catch(error => {
          console.error(error);
        })
    }
  },
  beforeMount() {
    this.getPermissions();
  },
  components: {},
};
</script>

<style>
.add-role .modal .modal-content {
  border-radius: 1rem;
  box-shadow: 1px 1px 5px #856161;
  padding: 2rem;
}
.add-role .modal .title{
  margin-bottom: 1rem !important;
}
.add-role .modal h2{
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 0;
}

/* Form */
.add-role .modal form label {
  display: block;
  margin: 1rem 0 .5rem 0;
  color: black;
}
.add-role .modal form input,
.add-role .modal form .v-select {
  border: 1px solid #CCC;
  background-color: #FFF;
  padding: .5rem 1rem;
  border-radius: 10px;
  width: 90%;
}
.add-role .modal form .v-select {
  outline: none;
  cursor: pointer;
}
.add-role .modal form .submit-btn {
  padding: 0.5rem 1rem;
  border-radius: 10px;
  color: #fff;
  box-shadow: 0 1px 5px #f1e7e7;
  font-weight: bold;
  display: block;
  margin-top: 2rem;
  width: 6rem;
}

/* V-Select */
.add-role .modal form .v-select{
  padding: 0;
}
.add-role .modal form .v-select.v-input--disabled{
  background-color: #f3f3f3;
}
.add-role .modal form .v-select .v-field__input{
  padding: .5rem 1rem;
  min-height: 2.8rem;
}
.add-role .modal form .v-select .v-field{
  border-radius: 10px;
}
.add-role .modal form .v-select span,
.add-role .modal form .v-select .v-icon{
  color: black;
}
.add-role .modal form .v-select input{
  border: none;
  background-color: transparent;
  padding: 0;
  border-radius: 0;
  width: 100%;
}
.add-role .modal form .v-select label{
  margin: 0 1rem;
}
.add-role .modal form .v-select .v-input__details{
  display: none;
}
</style>
